import { isWithinInterval, parse } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MY_STORE_COOKIE } from '../../../../../../_foundation/constants/cookie';
import { Weekdays } from '../../../../../../_foundation/enum/Weekdays/Weekdays';
import {
  MyStoreCookie,
  PhysicalStoreDetails,
  StoreAttribute,
} from '../../../../../../_foundation/interface/StoreLocator/IStoreLocator';
import {
  MAKE_MY_STORE_ACTION,
  RESET_MY_STORE_ACTION,
} from '../../../../../../redux/actions/storeLocator';
import { storeLocatorSelector } from '../../../../../../redux/selectors/storeLocator';
import {
  checkTruthy,
  formatTime,
  formatToTitleCase,
  getCurrentDay,
  setStoreSession,
} from '../../../../../../utils/utils';
import { StoreLocatorConstants } from '../StoreLocatorConstants';

/**
 * @method useStoreLocator is responsible for handling the business logic for the Store Locator Component.
 *
 * @returns
 * @param storeDetails Contains Details for all the available stores.
 * @param currentStore Contains the currentStore name.
 * @param nearByStoreDetails Contains the nearByStoreDetails or currently selected store details.
 */
const useStoreLocator = () => {
  const {
    FIND_A_STORE,
    OPENS_AT,
    OPEN_UNTIL,
    FRIDAY,
    SATURDAY,
    STORE_WEB_STATUS,
    LOCAL_DELIVERY,
    TEMPORARILY_CLOSED,
    SAME_DAY_PICKUP_NOT_AVAILABLE,
    PERMANENTLY_CLOSED,
    CLOSED,
    COMING_SOON,
    OPENS_AT_FALLBACK,
    OPEN_UNTIL_FALLBACK,
  } = StoreLocatorConstants;

  const dispatch = useDispatch();

  const { storeDetails, loading, showFindStore, currentStoreDetails } =
    useSelector(storeLocatorSelector);

  const [nearByStoreDetails, setNearByStoreDetails] = useState<
    PhysicalStoreDetails | MyStoreCookie
  >(storeDetails[0]);

  const { t, i18n } = useTranslation();

  const [storeClosed, setStoreClosed] = useState(false);

  const [storecloseMessage, setStoreCloseMessage] = useState('');

  const [currentStore, setCurrentStore] = useState<string>(t(FIND_A_STORE));

  /**
   * @callback setOpenCloseTiming Calculates whether the store is open until or
   * the store opens at based on the given time of the day.
   *
   * @param start start time of the store.
   * @param end end time of the store.
   * @param weekDayClosed flag to set today the store is closed
   * @param current current time of the store.
   */
  const setOpenCloseTiming = useCallback(
    (startTime: string, endTime: string, weekDayClosed: boolean): string => {
      const timeFormat = 'HH:mm:ss';

      let startDate = parse(
        `${startTime.slice(0, 2)}:${startTime.slice(2, 4)}:00`,
        timeFormat,
        new Date()
      );

      let endDate = parse(
        `${endTime.slice(0, 2)}:${endTime.slice(2, 4)}:00`,
        timeFormat,
        new Date()
      );

      let currentDate = new Date();
      const isIntervalAvailable = isWithinInterval(currentDate, {
        start: startDate,
        end: endDate,
      });

      /**
       * Responsible to set the Temporarily closed or Same Day Pickup Not Available message
       */
      if (currentStoreDetails) {
        const temporarilyClosed = currentStoreDetails.Attribute?.filter(
          (data: StoreAttribute) => data.name === STORE_WEB_STATUS
        );

        const sameDayPickUp = currentStoreDetails.Attribute?.filter(
          (data: StoreAttribute) => data.name === LOCAL_DELIVERY
        );

        if (
          temporarilyClosed &&
          temporarilyClosed[0]?.value === TEMPORARILY_CLOSED
        ) {
          setStoreCloseMessage(TEMPORARILY_CLOSED);
          setStoreClosed(false);
          return TEMPORARILY_CLOSED;
        }

        if (sameDayPickUp && checkTruthy(sameDayPickUp[0]?.value)) {
          setStoreClosed(false);
          setStoreCloseMessage(SAME_DAY_PICKUP_NOT_AVAILABLE);
        }
      }

      /**
       * weekDayClosed flag is true then the store is closed today
       */
      if (weekDayClosed && !storecloseMessage) {
        setStoreClosed(true);
      }

      /**
       * Store not closed today
       */
      if (isIntervalAvailable && !weekDayClosed) {
        setStoreClosed(false);
        return `${
          i18n.exists(OPEN_UNTIL).valueOf()
            ? t(OPEN_UNTIL)
            : OPEN_UNTIL_FALLBACK
        } ${formatTime(endDate, true, Number(endTime.slice(2, 4)) > 0)
          .toLowerCase()
          .replace(' ', '')}`;
      }

      /**
       * Today the stored opened, but it's closed now
       */
      if (!isIntervalAvailable && !weekDayClosed && currentStoreDetails) {
        /**
         * if the current day is Saturday or sunday we need to set the index to 0 or 1 in purpose of finding Sunday or monday
         */
        let weekIndex = '';
        let closedIndex = '';

        /**
         * Today the stored opened, but it's closed now  -- checking for next day is closed
         * Additional check for friday and saturday -- we need to change the index for sunday or monday
         */
        if (Weekdays[getCurrentDay()] === FRIDAY) {
          closedIndex = Weekdays[0];
        } else if (Weekdays[getCurrentDay()] === SATURDAY) {
          closedIndex = Weekdays[1];
        } else {
          closedIndex = Weekdays[getCurrentDay() + 2];
        }

        if (Weekdays[getCurrentDay()] === SATURDAY) {
          weekIndex = Weekdays[0];
        } else {
          weekIndex = Weekdays[getCurrentDay() + 1];
        }

        const currentTiming = currentStoreDetails.Attribute?.filter(
          (data: any) => data.name === weekIndex
        );

        /**
         *  Today the stored opened, but it's closed now  -- checking for next day is closed
         *  show the day after day opens at time
         * eg., tuesday the store is already been closed, check for the next day(wednesday) whether the store is closed or open
         * if it's closed, show the thursday (opening time)
         */
        if (
          currentTiming &&
          currentTiming[0].value.toLowerCase() === CLOSED &&
          new Date().getHours() > Number(endTime.slice(0, 2))
        ) {
          if (!storecloseMessage) {
            setStoreClosed(true);
          }

          if (Weekdays[getCurrentDay()] === SATURDAY) {
            weekIndex = Weekdays[1];
          } else {
            weekIndex = Weekdays[getCurrentDay() + 2];
          }

          const currentTiming = currentStoreDetails.Attribute?.filter(
            (data: any) => data.name === weekIndex
          );

          const storeTimings = currentTiming[0].value.split('_');

          const currentTimingStartDate = parse(
            `${storeTimings[0].slice(0, 2)}:${storeTimings[0].slice(2, 4)}:00`,
            timeFormat,
            new Date()
          );

          const opensMessage = `${t(OPENS_AT)} ${formatTime(
            currentTimingStartDate,
            true
          )
            .toLowerCase()
            .replace(' ', '')}   ${formatToTitleCase(closedIndex).substring(
            0,
            3
          )}`;
          return opensMessage;
        } else if (
          /**
           * Today the store is opened but it's closed now, if current time is greater than the current day's(store) start time
           * show the next day start time
           */
          currentStoreDetails &&
          new Date().getHours() > Number(startTime.slice(0, 2))
        ) {
          let weekIndex = '';

          if (Weekdays[getCurrentDay()] === SATURDAY) {
            weekIndex = Weekdays[0];
          } else {
            weekIndex = Weekdays[getCurrentDay() + 1];
          }

          const currentTiming = currentStoreDetails?.Attribute?.filter(
            (data: any) => data.name === weekIndex
          );

          const storeTimings =
            currentTiming && currentTiming[0].value.split('_');

          const currentTimingStartDate = parse(
            `${storeTimings[0].slice(0, 2)}:${storeTimings[0].slice(2, 4)}:00`,
            timeFormat,
            new Date()
          );

          return `${t(OPENS_AT)} ${formatTime(currentTimingStartDate, true)
            .toLowerCase()
            .replace(' ', '')}`;
        }
      }

      /**
       *  if current time is less than the current day's(store) start time, show the today's start time
       */
      return `${
        i18n.exists(OPENS_AT).valueOf() ? t(OPENS_AT) : OPENS_AT_FALLBACK
      } ${formatTime(startDate, true).toLowerCase().replace(' ', '')}`;
    },
    [
      CLOSED,
      FRIDAY,
      LOCAL_DELIVERY,
      OPENS_AT,
      OPEN_UNTIL,
      SAME_DAY_PICKUP_NOT_AVAILABLE,
      SATURDAY,
      STORE_WEB_STATUS,
      TEMPORARILY_CLOSED,
      currentStoreDetails,
      storecloseMessage,
      t,
      i18n,
      OPENS_AT_FALLBACK,
      OPEN_UNTIL_FALLBACK,
    ]
  );

  /**
   * @method setCurrentStoreTimings Constructs the store name along with opens at/open until
   * based on the current time of the given day.
   *
   * @param currentStoreDetails Current Store for which open/close time needs to be calculated
   */
  const setCurrentStoreTimings = useCallback(
    (currentStoreDetails: any): void => {
      if (!currentStoreDetails) {
        setCurrentStore(t(FIND_A_STORE));
        return;
      }
      const currentStoreTimings = currentStoreDetails?.Attribute?.filter(
        (data: any) => data.name === Weekdays[getCurrentDay()]
      );

      const storeWebStatus = currentStoreDetails?.Attribute.find(
        (attribute: any) =>
          attribute.displayName.toUpperCase() === STORE_WEB_STATUS
      )?.value?.toLowerCase();

      const temporarilyClosed =
        storeWebStatus === TEMPORARILY_CLOSED.toLowerCase();

      const comingSoon = storeWebStatus === COMING_SOON.toLowerCase();

      if (temporarilyClosed) {
        const temporarilyClosedMsg = `${currentStoreDetails.storeName} <span class="open">${TEMPORARILY_CLOSED}</span>`;

        setCurrentStore(temporarilyClosedMsg);
      } else if (comingSoon) {
        const comingSoonMsg = `${currentStoreDetails.storeName} <span class="open">${COMING_SOON}</span>`;

        setCurrentStore(comingSoonMsg);
      } else if (
        currentStoreTimings &&
        currentStoreTimings[0]?.value.toLowerCase() === CLOSED
      ) {
        let currentDay = getCurrentDay();

        let startDay = currentDay;
        let weekIndex = startDay;

        //let storeTime = getNextOpenStoreHours(startDay, currentDay, currentStoreDetails);

        let nextDayStoreTimings;
        let currentTiming;

        do {
          /**
           * if current day is Saturday we need to change index to Monday
           */
          if (Weekdays[weekIndex] === SATURDAY) {
            weekIndex = 0;
          } else {
            weekIndex = weekIndex + 1;
          }

          const weekDay = Weekdays[weekIndex];

          nextDayStoreTimings = currentStoreDetails.Attribute.filter(
            (data: any) => data.name === weekDay
          );

          currentTiming = nextDayStoreTimings[0].value;
        } while (
          currentTiming.toLowerCase() === CLOSED &&
          weekIndex !== startDay
        );

        let storeTime = currentTiming.split('_');
        if (storeTime[0].toLowerCase() === CLOSED) {
          // the status of the store should be closed at this point if all store hours are set to closed
          setCurrentStore(storeTime[0]);
        } else {
          const currentStoreTime = `${
            currentStoreDetails.storeName
          } <span class="open"> ${setOpenCloseTiming(
            storeTime[0],
            storeTime[1],
            true
          )} ${formatToTitleCase(Weekdays[weekIndex]).substring(0, 3)}</span>`;

          setCurrentStore(currentStoreTime);
        }
      } else {
        const storeTimings =
          currentStoreTimings && currentStoreTimings[0]?.value.split('_');

        if (storeTimings) {
          const currentStoreTime = `${
            currentStoreDetails.storeName
          } <span class="open">${setOpenCloseTiming(
            storeTimings[0],
            storeTimings[1],
            false
          )}</span>`;

          setCurrentStore(currentStoreTime);
        }
      }
    },
    [
      TEMPORARILY_CLOSED,
      COMING_SOON,
      CLOSED,
      t,
      FIND_A_STORE,
      STORE_WEB_STATUS,
      SATURDAY,
      setOpenCloseTiming,
    ]
  );

  /**
   * @callback initStoreDetails Initializes the nearest open store as
   * anchor store when current closest store is closed.
   */
  const initStoreDetails = useCallback((): void => {
    const mystoreValue = sessionStorage.getItem(MY_STORE_COOKIE);

    const mystore = mystoreValue ? JSON.parse(mystoreValue) : undefined;

    const anchorStore = storeDetails.find(
      (store) =>
        store?.storeName?.toLowerCase() ===
        (mystore && mystore?.storeName?.toLowerCase())
    );

    const storeWebStatus = anchorStore?.Attribute.find(
      (attribute: any) =>
        attribute.displayName.toLowerCase() === STORE_WEB_STATUS.toLowerCase()
    )?.value?.toLowerCase();

    const permanentlyClosed =
      storeWebStatus === PERMANENTLY_CLOSED.toLowerCase();

    const comingSoon = storeWebStatus === COMING_SOON.toLowerCase();

    if (!anchorStore && mystore?.storeName) {
      setNearByStoreDetails(mystore);

      setCurrentStoreTimings(mystore);
    } else if (!anchorStore || permanentlyClosed || comingSoon) {
      const currentStoreIndex = storeDetails?.findIndex(({ Attribute }) =>
        Attribute.find(
          ({ displayName, displayValue }) =>
            displayName.toLowerCase() === STORE_WEB_STATUS.toLowerCase() &&
            displayValue.toLowerCase() !== COMING_SOON.toLowerCase() &&
            displayValue.toLowerCase() !== PERMANENTLY_CLOSED.toLowerCase()
        )
      );

      if (
        storeDetails &&
        currentStoreIndex !== -1 &&
        storeDetails[currentStoreIndex]
      ) {
        dispatch(MAKE_MY_STORE_ACTION(storeDetails[currentStoreIndex]));

        setStoreSession(storeDetails[currentStoreIndex]);

        setNearByStoreDetails(storeDetails[currentStoreIndex]);

        setCurrentStoreTimings(storeDetails[currentStoreIndex]);
      } else {
        const emptyStore: PhysicalStoreDetails = {
          Attribute: [],
          Description: [{ displayStoreName: '' }],
          addressLine: [''],
          city: '',
          country: '',
          distance: '',
          isBopisEligible: false,
          latitude: '',
          longitude: '',
          postalCode: '',
          stateOrProvinceName: '',
          orderInventory: [],
          storeName: '',
          telephone1: '',
          uniqueID: '',
          inventory: 0,
          x_url: '',
        };

        dispatch(RESET_MY_STORE_ACTION());
        setCurrentStore(t(FIND_A_STORE));

        setNearByStoreDetails(emptyStore);
        setCurrentStoreTimings(undefined);
      }
    } else if (
      storeDetails.length > 0 &&
      mystore &&
      mystore.storeId &&
      !currentStoreDetails
    ) {
      const previousAnchorStoreIndex = storeDetails.findIndex(
        ({ uniqueID }) => uniqueID === mystore.storeId
      );

      dispatch(MAKE_MY_STORE_ACTION(storeDetails[previousAnchorStoreIndex]));

      setStoreSession(storeDetails[previousAnchorStoreIndex]);

      setNearByStoreDetails(storeDetails[previousAnchorStoreIndex]);

      setCurrentStoreTimings(storeDetails[previousAnchorStoreIndex]);

      return;
    }
  }, [
    COMING_SOON,
    FIND_A_STORE,
    PERMANENTLY_CLOSED,
    STORE_WEB_STATUS,
    currentStoreDetails,
    dispatch,
    setCurrentStoreTimings,
    storeDetails,
    t,
  ]);

  /**
   * Check for cookies as
   * soon as this component mounts.
   */
  useEffect(() => {
    if (!loading && !showFindStore && storeDetails) {
      initStoreDetails();
    }
  }, [dispatch, initStoreDetails, loading, showFindStore, storeDetails]);

  /**
   * @method makeMyStore Looks for whether thw currentStoreDetails
   * have been updated after the user clicks on makeMyStore and
   * updates the menu state accordingly.
   */
  const makeMyStore = useCallback(
    (currentStoreDetails: PhysicalStoreDetails): void => {
      setNearByStoreDetails(currentStoreDetails);

      setCurrentStoreTimings(currentStoreDetails);
    },
    [setCurrentStoreTimings]
  );

  useEffect(() => {
    if (currentStoreDetails) {
      makeMyStore(currentStoreDetails);
    }
  }, [currentStoreDetails, makeMyStore]);

  return {
    currentStore,
    loading,
    nearByStoreDetails,
    storeDetails,
    storeClosed,
    storecloseMessage,
    initStoreDetails,
  };
};

export { useStoreLocator };
