import { Grid, Hidden, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Error } from '../../../components/Error/Error';
import { productListSelector } from '../../../redux/selectors/productList.selector';
import useWindowSize from '../../../utils/hooks/use-window-size/useWindowSize';
import {
  checkTruthy,
  scrollToTop,
  sendTealiumData,
} from '../../../utils/utils';
import { PlpPageView } from '../../../_foundation/enum/ProductList/ProductList';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import { IProductListContents } from '../../../_foundation/interface/ProductList/IProductList';
import { NteButton } from '../../Forms/NteButton/NteButton';
import { NteSelect } from '../../Forms/NteSelect/NteSelect';
import { PlplayoutConstants } from '../../Layouts/Plp/PlpLayoutConstants';
import { NteAlert } from '../../NteAlert/NteAlert';
import { FacetMobile } from '../Facets/FacetMobile/FacetMobile';
import { GridProductCard } from '../Plp/GridProductCard/GridProductCard';
import { ProductCard } from '../Plp/ProductCard/ProductCard';
import { ProductCardSkeleton } from '../Plp/ProductCard/ProductCardSkeleton/ProductCardSkeleton';

/**
 * @interface ProductCardsProps.
 */
export interface ProductCardsProps {
  productList: Array<IProductListContents[]>;
  totalProducts: number;
  activeSortIndex?: number;
  updateSortIndex?: (sortIndex: number) => void;
  showSortOptions?: boolean;
}

/**
 *
 * @param ProductCardsProps.
 * @returns ProductCards Component.
 */
const ProductCards: React.FC<ProductCardsProps> = ({
  productList,
  totalProducts,
  activeSortIndex,
  updateSortIndex,
  showSortOptions = true,
}) => {
  const { getProductCountPerPage, LIST, GRID, PLP_VIEW } = PlplayoutConstants;

  const windowSize = useWindowSize();

  const productCountPerPage = getProductCountPerPage();

  const { mySite } = useSite();

  const { pageLoading } = useSelector(productListSelector);

  // Initialize translation.
  const { t } = useTranslation();

  const getProductCount = useCallback(
    (pageNumber: number) => {
      const startProductCount = pageNumber * productCountPerPage + 1;

      const endProductCount =
        (pageNumber + 1) * productCountPerPage > totalProducts
          ? totalProducts
          : (pageNumber + 1) * productCountPerPage;

      return {
        startProductCount,
        endProductCount,
      };
    },
    [productCountPerPage, totalProducts]
  );

  const { loading, facetLoading, plpError, selectedFacets } =
    useSelector(productListSelector);

  const selectedFacetCount = selectedFacets.length;

  const showProductCards =
    productList && productList.length !== 0 && productList[0];

  const [view, setView] = useState('');

  const [stickyClass, setStickyClass] = useState('relative');

  const [distanceFromTop, setDistanceFromTop] = useState(298);

  const stickyBar = document.querySelector('.sort-filter-container');

  const stickNavbar = useCallback(() => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;

      windowHeight > distanceFromTop
        ? setStickyClass('fixed')
        : setStickyClass('relative');
    }
  }, [distanceFromTop]);

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    if (stickyBar) {
      setDistanceFromTop(
        window.pageYOffset + stickyBar.getBoundingClientRect().top
      );
    }

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, [stickNavbar, stickyBar]);

  const toggleOnChangeHandler = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    sessionStorage.setItem(PLP_VIEW, nextView);

    setView(nextView);

    if (nextView === PlpPageView.GRID) {
      sendTealiumData({
        tealium_event: 'plp_grid_view',
      });
    } else if (nextView === PlpPageView.LIST) {
      sendTealiumData({
        tealium_event: 'plp_list_view',
      });
    }
  };

  // to set the default view for smaller devices
  const initView = useCallback(() => {
    const defaultView = mySite?.defaultPlpView.toLowerCase();

    if (windowSize.width > 768) {
      const plpView = sessionStorage.getItem(PLP_VIEW);
      if (plpView && plpView !== '') {
        setView(plpView);
      } else {
        if (defaultView.toUpperCase() in PlpPageView) {
          setView(defaultView);
          sessionStorage.setItem(PLP_VIEW, defaultView);
        }
      }
    } else {
      setView(GRID);
      sessionStorage.setItem(PLP_VIEW, GRID);
    }
  }, [windowSize, mySite, GRID, PLP_VIEW]);

  useEffect(() => {
    if (mySite && checkTruthy(mySite?.enablePlpGridView)) {
      initView();
    } else {
      setView(LIST);
      sessionStorage.setItem(PLP_VIEW, LIST);
    }
  }, [initView, LIST, PLP_VIEW, mySite]);

  const productCardView = () => {
    let card = productList.map((productListPerPage, pageNumber) => {
      const { startProductCount, endProductCount } =
        getProductCount(pageNumber);

      return (
        <div key={pageNumber} className='product-container'>
          <Typography className='page-count-container' variant='h2'>
            {pageNumber !== 0 && (
              <span className='page-number-container'>{pageNumber + 1}</span>
            )}
            {`${startProductCount}-${endProductCount} of ${totalProducts} Products`}
          </Typography>

          {startProductCount === 1 && (
            <>
              {showSortOptions && updateSortIndex && (
                <>
                  <div className={`sort-filter-container ${stickyClass}`}>
                    <div
                      className={`sort-only ${
                        !checkTruthy(mySite?.enablePlpGridView)
                          ? 'sort-only-right-0'
                          : ''
                      }`}>
                      {windowSize.width < 900 ? (
                        <Hidden mdUp>
                          <FacetMobile />
                        </Hidden>
                      ) : (
                        <Hidden smUp>
                          <FacetMobile />
                        </Hidden>
                      )}
                      {showProductCards && (
                        <>
                          <label htmlFor='plp-sort'>
                            <Typography variant='h2'>
                              {t(PlplayoutConstants.TRANSLATIONS.SORT)}
                            </Typography>
                          </label>

                          <NteSelect
                            className='plp-sort-select'
                            IconComponent={KeyboardArrowDownIcon}
                            native
                            onChange={(event: any) => {
                              updateSortIndex(event.target.value);
                            }}
                            options={PlplayoutConstants.SORT_OPTIONS}
                            selectFormId='plp-sort'
                            value={activeSortIndex}
                            variant='outlined'
                            selectLabel='Sort'
                          />
                        </>
                      )}
                    </div>
                    {checkTruthy(mySite?.enablePlpGridView) && (
                      <Hidden xsDown>
                        <ToggleButtonGroup
                          value={view}
                          exclusive
                          onChange={toggleOnChangeHandler}
                          className={'plp-grid'}>
                          <ToggleButton value='list' aria-label='list'>
                            <ViewListIcon />
                          </ToggleButton>
                          <ToggleButton value='grid' aria-label='grid'>
                            <ViewModuleIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Hidden>
                    )}

                    <Hidden mdUp>
                      <div>
                        <NteButton
                          variant='outlined'
                          translationKey='Back to Top'
                          className='back-to-top'
                          type='button'
                          onClick={scrollToTop}
                        />
                        <IconButton
                          aria-label='delete'
                          className='back-to-top-icon round-button'
                          onClick={scrollToTop}>
                          <ArrowUpwardIcon />
                        </IconButton>
                      </div>
                    </Hidden>
                  </div>
                </>
              )}
            </>
          )}
          {view === GRID ? (
            <Grid container spacing={2}>
              {productListPerPage.map(
                (product: IProductListContents, index: number) => (
                  <Grid
                    item
                    xs={windowSize.width > 450 ? 6 : 12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={3}>
                    <GridProductCard
                      key={product.id}
                      productCard={product}
                      index={index}
                      sortIndex={activeSortIndex}
                    />
                  </Grid>
                )
              )}
            </Grid>
          ) : (
            <ul
              key={pageNumber}
              className={`product-list product_cards section-row`}>
              {productListPerPage.map(
                (product: IProductListContents, index: number) => (
                  <li
                    key={index}
                    className='product-block'
                    data-product-id={product.partNumber}
                    data-brand-name={product?.manufacturer}>
                    <ProductCard
                      productCard={product}
                      index={index}
                      sortIndex={activeSortIndex}
                    />
                  </li>
                )
              )}
            </ul>
          )}

          {pageLoading && (
            <ul>
              <ProductCardSkeleton />
            </ul>
          )}
        </div>
      );
    });
    return card;
  };
  let product_card = productCardView();
  return (
    <>
      {!loading && !facetLoading ? (
        <>
          {!plpError ? (
            <>
              {showProductCards ? (
                productList.map((productListPerPage, pageNumber) => {
                  const { startProductCount, endProductCount } =
                    getProductCount(pageNumber);

                  return (
                    <div key={pageNumber} className='product-container'>
                      <Typography className='page-count-container' variant='h2'>
                        {pageNumber !== 0 && (
                          <span className='page-number-container'>
                            {pageNumber + 1}
                          </span>
                        )}
                        {`${startProductCount}-${endProductCount} of ${totalProducts} Products`}
                      </Typography>

                      {startProductCount === 1 && (
                        <>
                          {showSortOptions && updateSortIndex && (
                            <>
                              <div
                                className={`sort-filter-container ${stickyClass}`}>
                                <div
                                  className={`sort-only ${
                                    !checkTruthy(mySite?.enablePlpGridView)
                                      ? 'sort-only-right-0'
                                      : ''
                                  }`}>
                                  {windowSize.width < 900 ? (
                                    <Hidden mdUp>
                                      <FacetMobile />
                                    </Hidden>
                                  ) : (
                                    <Hidden smUp>
                                      <FacetMobile />
                                    </Hidden>
                                  )}
                                  {showProductCards && (
                                    <>
                                      <label htmlFor='plp-sort'>
                                        <Typography variant='h2'>
                                          {t(
                                            PlplayoutConstants.TRANSLATIONS.SORT
                                          )}
                                        </Typography>
                                      </label>

                                      <NteSelect
                                        className='plp-sort-select'
                                        IconComponent={KeyboardArrowDownIcon}
                                        native
                                        onChange={(event: any) => {
                                          updateSortIndex(event.target.value);
                                        }}
                                        options={
                                          PlplayoutConstants.SORT_OPTIONS
                                        }
                                        selectFormId='plp-sort'
                                        value={activeSortIndex}
                                        variant='outlined'
                                        selectLabel='Sort'
                                      />
                                    </>
                                  )}
                                </div>
                                {checkTruthy(mySite?.enablePlpGridView) && (
                                  <Hidden xsDown>
                                    <ToggleButtonGroup
                                      value={view}
                                      exclusive
                                      onChange={toggleOnChangeHandler}
                                      className={'plp-grid'}>
                                      <ToggleButton
                                        value='list'
                                        aria-label='list'>
                                        <ViewListIcon />
                                      </ToggleButton>
                                      <ToggleButton
                                        value='grid'
                                        aria-label='grid'>
                                        <ViewModuleIcon />
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  </Hidden>
                                )}

                                <div className='backToTopContainer'>
                                  <NteButton
                                    variant='outlined'
                                    translationKey='Back to Top'
                                    className='back-to-top'
                                    type='button'
                                    onClick={scrollToTop}
                                  />
                                  <IconButton
                                    aria-label='delete'
                                    className='back-to-top-icon round-button'
                                    onClick={scrollToTop}>
                                    <ArrowUpwardIcon />
                                  </IconButton>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {view === GRID ? (
                        <Grid container spacing={2}>
                          {productListPerPage.map(
                            (product: IProductListContents, index: number) => (
                              <Grid
                                item
                                xs={windowSize.width > 450 ? 6 : 12}
                                sm={6}
                                md={4}
                                lg={4}
                                xl={3}>
                                <GridProductCard
                                  productCard={product}
                                  index={index}
                                  sortIndex={activeSortIndex}
                                />
                              </Grid>
                            )
                          )}
                        </Grid>
                      ) : (
                        <ul
                          key={pageNumber}
                          className={`product-list product_cards section-row`}>
                          {productListPerPage.map(
                            (product: IProductListContents, index: number) => (
                              <li
                                key={index}
                                className='product-block '
                                data-product-id={product.partNumber}
                                data-brand-name={product?.manufacturer}>
                                <ProductCard
                                  productCard={product}
                                  index={index}
                                  sortIndex={activeSortIndex}
                                />
                              </li>
                            )
                          )}
                        </ul>
                      )}

                      {pageLoading && (
                        <ul>
                          <ProductCardSkeleton />
                        </ul>
                      )}
                    </div>
                  );
                })
              ) : (
                <>
                  <Typography variant='h3'>
                    {t(PlplayoutConstants.TRANSLATIONS.ZERO_PRODUCTS)}
                  </Typography>
                  <div className='nte-card'>
                    <NteAlert
                      messages={
                        selectedFacets.length !== 0
                          ? [
                              t(
                                PlplayoutConstants.TRANSLATIONS
                                  .REMOVE_SOME_FILTERS
                              ),
                            ]
                          : [
                              t(
                                PlplayoutConstants.TRANSLATIONS
                                  .NO_PRODUCT_MESSAGE
                              ),
                            ]
                      }
                      severity='warning'
                      showClose={false}
                    />
                    <Hidden lgUp>
                      <Typography variant='body1'>
                        {t(PlplayoutConstants.TRANSLATIONS.FILTER_MESSAGE, {
                          selectedFacetCount,
                        })}
                        <FacetMobile
                          translationKey={t(
                            PlplayoutConstants.TRANSLATIONS.EDIT_FILTER
                          )}
                        />
                      </Typography>
                    </Hidden>
                  </div>
                </>
              )}
            </>
          ) : (
            <Error />
          )}
        </>
      ) : (
        <ul className='product-list section-row'>
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
        </ul>
      )}
    </>
  );
};

export { ProductCards };
