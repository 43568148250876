export const MY_STORE_COOKIE = 'MyStore';
export const NEAR_BY_STORES = 'NearByStores';
export const USER_PRODUCT_LIST = 'UserProductList';
export const USER = 'User';
export const SEARCH_HISTORY = 'SearchHistory';
export const USER_COUNTRY = 'USER_COUNTRY';
export const USER_TYPE = 'DMESpot_member';
export const PREV_USER_TYPE = ' PrevUserType';
export const GUEST_USER = 'GuestUser';
export const RECENTLY_VISITED_LINKS = 'RVILINKS';
export const CONTINUE_SHOPPING_LINKS = 'CONTINUESHOPPINGLINKS';
export const CART_COUNT_BEFORE_MERGING = 'CartCountBeforeMerging';
export const SESSION_ID = 's_fid';
export const PREV_ORDER_ID = 'prevOrderId';
export const REGION_AD = 'DMESpot_region';
export const CART_COOKIE = 'CartInfo';
export const USER_CONTRACT = 'UserContract';
export const FORTER_TOKEN = 'forterToken';
export const CHECKOUT_SESSION_OUT = 'CheckoutSessionOut';
export const USER_IP = 'UserIP';
export const GC_LIMIT_EXCEEDED = 'gcLimitExceeded';
