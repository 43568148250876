export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const SEARCH_PRODUCT_LIST = 'SEARCH_PRODUCT_LIST';
export const SEARCH_PRODUCT_LIST_SUCCESS = 'SEARCH_PRODUCT_LIST_SUCCESS';
export const RESET_PRODUCT_LIST = 'RESET_PRODUCT_LIST';
export const SET_FACET = 'SET_FACET';
export const CLEAR_ALL_FACET = 'CLEAR_ALL_FACET';
export const ADD_PRODUCTS_TO_COMPARE = 'ADD_PRODUCTS_TO_COMPARE';
export const REMOVE_PRODUCT_FROM_COMPARE = 'REMOVE_PRODUCT_FROM_COMPARE';
export const SET_PRODUCT_LIST_LOADER = 'SET_PRODUCT_LIST_LOADER';
export const FETCH_PRIVATE_BRANDS = 'FETCH_PRIVATE_BRANDS';
export const FETCH_PRIVATE_BRANDS_SUCCESS = 'FETCH_PRIVATE_BRANDS_SUCCESS';
export const UPDATE_FACETS = 'UPDATE_FACETS';
export const UPDATE_FACETS_STORE_IDENTIFIER = 'UPDATE_FACETS_STORE_IDENTIFIER';
export const UPDATE_FACETS_SUCCESS = 'UPDATE_FACETS_SUCCESS';
export const FACET_LOADING = 'FACET_LOADING';
export const UPDATE_SELECTED_FACET = 'UPDATE_SELECTED_FACET';
export const FILTER_BY_PRICE_RANGE = 'FILTER_BY_PRICE_RANGE';
export const SHOW_PLP_META_TAGS = 'SHOW_PLP_META_TAGS';
export const SHOW_PLP_ERROR = 'SHOW_PLP_ERROR';
export const FACETS_STACK = 'FACETS_STACK';
export const SET_FACET_FROM_PARAMS = 'SET_FACET_FROM_PARAMS';
export const CLEAR_PRICE_RANGE_FACET = 'CLEAR_PRICE_RANGE_FACET';
export const SET_PLP_PAGE_LOADING = 'SET_PLP_PAGE_LOADING';
export const SEARCH_DISPLAY_REQUEST = 'SEARCH_DISPLAY_REQUEST';
export const SEARCH_DISPLAY_SUCCESS = 'SEARCH_DISPLAY_SUCCESS';
export const RESET_SEARCH_DISPLAY = 'RESET_SEARCH_DISPLAY';
export const UPDATE_SEARCH_PRODUCT_LIST = 'UPDATE_SEARCH_PRODUCT_LIST';
export const SEARCH_PRODUCT_REDIRECTION = 'SEARCH_PRODUCT_REDIRECTION';
export const UPDATE_FACETS_ACTION_STORE_IDENFIER =
  'UPDATE_FACETS_ACTION_STORE_IDENFIER';
export const GET_PRODUCT_LIST_STORE = 'GET_PRODUCT_LIST_STORE';
export const FETCH_INVENTORY_INFO = 'FETCH_INVENTORY_INFO';
export const FETCH_INVENTORY_INFO_SUCCESS = 'FETCH_INVENTORY_INFO_SUCCESS';
export const TOP_BRANDS_SUCCESS = 'TOP_BRANDS_SUCCESS';
export const RESET_TOP_BRANDS = 'RESET_TOP_BRANDS';
export const REDIRECT_TO_PDP = 'REDIRECT_TO_PDP';
export const NO_SEARCH_RESULTS_FOUND = 'NO_SEARCH_RESULTS_FOUND';
export const ADD_CATEGORY_IDS = 'ADD_CATEGORY_IDS';
export const CURRENT_BRAND_ID = 'CURRENT_BRAND_ID';
export const CURRENT_BRAND_IDENTIFIER = 'CURRENT_BRAND_IDENTIFIER';
export const CURRENT_BRAND_INFO = 'CURRENT_BRAND_INFO';
export const FETCH_SUB_CATEGORIES = 'FETCH_SUB_CATEGORIES';
export const FETCH_SUB_CATEGORIES_SUCCESS = 'FETCH_SUB_CATEGORIES_SUCCESS';
export const FETCH_INVENTORY_BY_IDENTIFIER = 'FETCH_INVENTORY_BY_IDENTIFIER';
export const FETCH_INVENTORY_BY_IDENTIFIER_SUCCESS =
  'FETCH_INVENTORY_BY_IDENTIFIER_SUCCESS';
export const FETCH_BRAND_BY_IDENTIFIER = 'FETCH_BRAND_BY_IDENTIFIER';
export const SET_PROMO_PRODUCT_ID = 'SET_PROMO_PRODUCT_ID';
export const GET_PRODUCT_LIST_ACTION_STORAGE =
  'GET_PRODUCT_LIST_ACTION_STORAGE';
