import { CircularProgress } from '@material-ui/core';
import PlaceIcon from '@material-ui/icons/Place';
import React, { useRef } from 'react';
import { useInitStoreLocator } from '../../../../../utils/hooks/StoreLocator/init-storeLocator/init-storeLocator';
import { useNtePopper } from '../../../../Poppers/NtePopper/hooks/NtePopperHooks';
import { NavMenuItem } from '../../NavMenuItem/NavMenuItem';
import { useStoreLocator } from './hooks/StoreLocatorHooks';
import { MyStore } from './MyStore/MyStore';
import { Skeleton } from '@material-ui/lab';

/**
 * @component StoreLocator renders the store location popper component.
 */
const StoreLocator: React.FC = () => {
  const locationMenuRef = useRef(null);

  const { RenderPopper, ntePopperClickHandler, showPopper } = useNtePopper({
    id: 'my-store-menu',
    ariaLabelledById: 'store-locator-header',
  });

  useInitStoreLocator();

  const { currentStore, nearByStoreDetails, loading } = useStoreLocator();

  return !loading ? (
    <>
      <NavMenuItem
        imgAlt='location-icon'
        className='store-top-menu store_locator'
        menuAnchor={locationMenuRef}
        menuIconSvg={<PlaceIcon className='menu_icon' />}
        menuTitle={currentStore}
        onClick={ntePopperClickHandler}
        disablePopperAction={showPopper}
      />

      {showPopper && (
        <RenderPopper
          anchor={locationMenuRef}
          showPopper={showPopper}
          ntePopperCloseHandler={ntePopperClickHandler}>
          <MyStore
            myStoreCloseHandler={ntePopperClickHandler}
            nearByStoreDetails={nearByStoreDetails}
          />
        </RenderPopper>
      )}
    </>
  ) : (
    <Skeleton className='nav-bar-skeleton-bg find_Store_Skeleton' />
  );
};

export { StoreLocator };
