import {
  IProductApiResult,
  IProductListState,
  ISearchDisplayState,
} from '../../_foundation/interface/ProductList/IProductList';
import { RootReducerState } from '../reducers';

const productListSelector = (state: RootReducerState): IProductListState => {
  return state.productList;
};

const productListStorageSelector = (
  state: RootReducerState
): IProductApiResult => {
  return state.tempProductList;
};

const searchDisplaySelector = (
  state: RootReducerState
): ISearchDisplayState => {
  return state.searchDisplay;
};

export {
  productListSelector,
  searchDisplaySelector,
  productListStorageSelector,
};
