import { Box, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NteAccordion } from '../../../Widgets/Accordion/NteAccordion';
import { FooterLinksConstants } from '../FooterLinksConstants';
import { useEspotFooterLinks } from '../Hooks/useEspotFooterLinks';
import { Skeleton } from '@material-ui/lab';

const AboutUsLinks: React.FC = () => {
  const E_SPOT_NAME = 'FOOTER_01C';

  /* Initialize Translation */
  const { t, i18n } = useTranslation();

  const { ABOUT_US, ABOUT_FALLBACK } = FooterLinksConstants;

  const title = i18n.exists(ABOUT_US).valueOf() ? t(ABOUT_US) : ABOUT_FALLBACK;

  const { parsedEspotLinks: aboutUsLinks } = useEspotFooterLinks(E_SPOT_NAME);

  return (
    <Box className='footer_links_block about-us'>
      <div className='tablet-only'>
        <Typography variant='h3'>{title}</Typography>
        {aboutUsLinks.length > 0 ? (
          <ul>{aboutUsLinks.map((link: JSX.Element) => link)}</ul>
        ) : (
          <ul>
            <li className="footer_link"><Skeleton /></li>
            <li className="footer_link"><Skeleton /></li>
            <li className="footer_link"><Skeleton /></li>
            <li className="footer_link"><Skeleton /></li>
            <li className="footer_link"><Skeleton /></li>
            <li className="footer_link"><Skeleton /></li>
            <li className="footer_link"><Skeleton /></li>
            <li className="footer_link"><Skeleton /></li>
          </ul>
        )}
      </div>
      <Hidden smUp>
        <NteAccordion
          accordionTitle={title}
          defaultExpanded={false}
          className='no-elevation'>
          <ul>{aboutUsLinks.map((link: JSX.Element) => link)}</ul>
        </NteAccordion>
      </Hidden>
    </Box>
  );
};

export default AboutUsLinks;
