import { Hidden } from '@material-ui/core';
import { AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  PLP_SORT_FINDING_METHOD_TEALIUM,
  PLP_SORT_TEALIUM,
} from '../../../constants/Tealium';
import {
  RESET_PRODUCT_LIST_ACTION,
  SET_PLP_PAGE_LOADING_ACTION,
} from '../../../redux/actions/productList.actions';
import { breadcrumbSelector } from '../../../redux/selectors/breadcrumb.selector';
import { useBrandParams } from '../../../utils/hooks/brand-params/brand-params';
import { useCurrentPathName } from '../../../utils/hooks/current-pathname/CurrentPathName';
import { useDealsCheck } from '../../../utils/hooks/deals-check/DealsCheckHooks';
import {
  constructESpotId,
  formatToTitleCase,
  sendTealiumData,
} from '../../../utils/utils';
import { makeRequest } from '../../../_foundation/apis/axios/axiosConfig';
import { getPageHash } from '../../../_foundation/apis/BrightEdge/BrightEdgeService';
import { BreadcrumbType } from '../../../_foundation/enum/Breadcrumbs/Breadcrumbs';
import { MiniCartDrawer } from '../../MiniCart/MiniCartDrawer';
import { NteEspot } from '../../NteEspot/NteEspot';
import { seoSelector } from '../../Seo/redux/selector/seo';
import { CategoryCarousel } from '../../Widgets/Categories/CategoryCarousel/ CategoryCarousel';
import { FacetDesktop } from '../../Widgets/Facets/FacetDesktop/FacetDesktop';
import { PageHeader } from '../../Widgets/PageHeader/PageHeader';
import { Pagination } from '../../Widgets/Pagination/Pagination';
import { PlpFeedback } from '../../Widgets/Plp/PlpFeedback/PlpFeedback';
import { ProductComparisonBar } from '../../Widgets/Plp/ProductComparisonBar/ProductComparisonBar';
import { ProductCards } from '../../Widgets/ProductCards/ProductCards';
import { Rvi } from '../../Widgets/Rvi/Rvi';
import { ISeoSubHead } from '../../Widgets/SeoSubHead/SeoSubHead';
import { CATEGORY_LAYOUT_CONSTANTS } from '../Category/CategoryLayoutConstants';
import { usePlp } from './hooks/PlpLayoutHooks';
import { PlplayoutConstants } from './PlpLayoutConstants';
import * as DOMPurify from 'dompurify';
import useWindowSize from '../../../utils/hooks/use-window-size/useWindowSize';

/**
 * @interface PlpLayoutProps
 */
export interface PlpLayoutProps {
  /** Props required for seo subhead Section. */
  seoSubHead: ISeoSubHead;

  /** Props required for espot 1. */
  plpEspot1: {
    espotId: string;
  };

  /** Props required for espot 1A. */
  plpEspot1A: {
    espotId: string;
  };

  /** Props required for espot 2. */
  plpEspot2: {
    espotId: string;
  };
}

/**
 *
 * @param PlpLayoutProps
 * @returns PlpLayout Component.
 */
const PlpLayout: React.FC<PlpLayoutProps> = ({
  seoSubHead,
  plpEspot1,
  plpEspot1A,
  plpEspot2,
}) => {
  const { ORDER_BY_PARAM, SORT_OPTIONS, PROD_URL } = PlplayoutConstants;

  const {
    categoryPageTitle,
    productList,
    productListLoading,
    setActiveSortIndex,
    setLastPageNumber,
    showProductComparisonBar,
    totalProducts,
    activeSortIndex,
    lastPageNumber,
    pageLoading,
    isFacetsAvailable,
    isBrandPlp,
    isCollectionPlp,
    currentCategoryId,
  } = usePlp();

  const dispatch = useDispatch();

  const windowSize = useWindowSize();

  const { currentPathName } = useCurrentPathName();

  const [brandImage, setBrandImage] = useState<string>();

  const [collectionImage, setCollectionImage] = useState<string>();

  const { brands: brandNames } = useBrandParams({ seperator: ', ' });

  const [brightEdgeString, setBrightEdgeString] = useState('');

  const [brightEdgeHeadString, setBrightEdgeHeadString] = useState('');

  const location = useLocation();

  //(history.action === "POP")&&localStorage.setItem("Back","true");

  /**
   * @method formattedPath
   * Responsible to Format the current path like "/categories/power-tools" as PowerTools
   */
  const formattedPath = formatToTitleCase(
    currentPathName.replace(/-/g, ' ')
  ).replace(/ /g, '');

  const seoConfig = useSelector(seoSelector);

  const { breadCrumbTrailEntryView } = useSelector(breadcrumbSelector);

  const { isDealsPage } = useDealsCheck();

  const { TOKEN_NAME } = CATEGORY_LAYOUT_CONSTANTS;

  const categoryCarouselTitle = useMemo(() => {
    if (
      breadCrumbTrailEntryView &&
      breadCrumbTrailEntryView.length > 0 &&
      breadCrumbTrailEntryView[breadCrumbTrailEntryView.length - 1].label
    ) {
      return breadCrumbTrailEntryView[breadCrumbTrailEntryView.length - 1]
        .label;
    }

    return categoryPageTitle;
  }, [breadCrumbTrailEntryView, categoryPageTitle]);

  const pageTitle = useMemo(
    () =>
      isBrandPlp ? categoryPageTitle : `${brandNames}${categoryPageTitle}`,
    [brandNames, categoryPageTitle, isBrandPlp]
  );

  /**
   * @method findActivePlp
   * Responsible to find the acive plp to get the right value from redux
   */
  const findActivePlp = useCallback((): void => {
    const identifier =
      seoConfig &&
      seoConfig[formattedPath] &&
      seoConfig[formattedPath].tokenName === TOKEN_NAME &&
      seoConfig[formattedPath].identifier?.toLowerCase();
    if (isCollectionPlp) {
      const imgUrl = `${process.env.REACT_APP_COLLECTION_IMAGE_URL}/${identifier}.jpg`;
      setCollectionImage(imgUrl);
    } else {
      const imgUrl = `${process.env.REACT_APP_BRAND_IMAGE_URL}/${identifier}.jpg`;
      seoConfig[formattedPath]?.isBrandPlp && setBrandImage(imgUrl);
    }
  }, [TOKEN_NAME, formattedPath, seoConfig, isCollectionPlp]);

  useEffect(() => {
    findActivePlp();
  }, [findActivePlp, seoConfig]);

  const [orderByParam, setOrderByParam] = useQueryParam(
    ORDER_BY_PARAM,
    StringParam
  );

  const updateSortIndex = (sortIndex: number) => {
    if (sortIndex !== activeSortIndex) {
      dispatch(
        RESET_PRODUCT_LIST_ACTION({
          preserveSelectedFacet: true,
          preserveSubCategories: true,
        })
      );

      setActiveSortIndex(Number(sortIndex));

      setOrderByParam(String(sortIndex), 'replaceIn');

      const { label } = SORT_OPTIONS.filter(
        ({ value }) => value === Number(sortIndex)
      )[0];

      sendTealiumData({
        tealium_event: PLP_SORT_TEALIUM,
        plp_sort_option: label.toLowerCase(),
        product_finding_method: PLP_SORT_FINDING_METHOD_TEALIUM,
      });
    }
  };

  /**
   * @callback initOrderByParam Checks for the orderBy query param when the page loads up.
   */
  const initOrderByParam = useCallback((): void => {
    if (orderByParam) {
      setActiveSortIndex(Number(orderByParam));
    }
  }, [orderByParam, setActiveSortIndex]);

  useEffect(() => {
    initOrderByParam();
  }, [initOrderByParam]);

  const updatePageNumber = () => {
    if (!isFacetsAvailable) {
      dispatch(SET_PLP_PAGE_LOADING_ACTION(true));
    }

    setLastPageNumber((lastPageNumber) => {
      return lastPageNumber + 1;
    });
  };

  useEffect(() => {
    return () => {
      setOrderByParam(undefined, 'replaceIn');
    };
  }, [dispatch, setOrderByParam]);

  const pageHeaderEspot = useMemo(() => {
    const identifier =
      seoConfig &&
      seoConfig[formattedPath] &&
      seoConfig[formattedPath].tokenName === TOKEN_NAME &&
      seoConfig[formattedPath].identifier?.toLowerCase();

    if (identifier) {
      return constructESpotId(plpEspot1.espotId, identifier);
    }

    return '';
  }, [TOKEN_NAME, formattedPath, plpEspot1.espotId, seoConfig]);

  const plpEspot1AId = useMemo(() => {
    const identifier =
      seoConfig &&
      seoConfig[formattedPath] &&
      seoConfig[formattedPath].tokenName === TOKEN_NAME &&
      seoConfig[formattedPath].identifier?.toLowerCase();

    if (identifier) {
      return constructESpotId(plpEspot1A.espotId, identifier);
    }

    return '';
  }, [TOKEN_NAME, formattedPath, plpEspot1A.espotId, seoConfig]);

  const plpEspot2Id = useMemo(() => {
    const identifier =
      seoConfig &&
      seoConfig[formattedPath] &&
      seoConfig[formattedPath].tokenName === TOKEN_NAME &&
      seoConfig[formattedPath].identifier?.toLowerCase();

    if (identifier) {
      return constructESpotId(plpEspot2.espotId, identifier);
    }

    return '';
  }, [TOKEN_NAME, formattedPath, plpEspot2.espotId, seoConfig]);

  const seoSubHeadEspot = useMemo(() => {
    const identifier =
      seoConfig &&
      seoConfig[formattedPath] &&
      seoConfig[formattedPath].tokenName === TOKEN_NAME &&
      seoConfig[formattedPath].identifier?.toLowerCase();

    if (identifier) {
      return constructESpotId(seoSubHead.espotId, identifier);
    }

    return '';
  }, [TOKEN_NAME, formattedPath, seoConfig, seoSubHead.espotId]);

  const initBrightEdge = useCallback(() => {
    const hash = getPageHash(PROD_URL + location.pathname);
    const brightEdgeRequest: AxiosRequestConfig = {
      url: `${process.env.REACT_APP_BRIGHTEDGE_BASE_URL}${process.env.REACT_APP_BRIGHTEDGE_ACCOUNT}/${hash}`,
      method: 'GET',
    };

    const brightEdgeResponse = makeRequest(brightEdgeRequest);
    brightEdgeResponse.then((response) => {
      setBrightEdgeString(response?.nodes[0]?.content);
      setBrightEdgeHeadString(response?.nodes[1]?.content);
    });
  }, [location.pathname, PROD_URL]);

  useEffect(() => {
    initBrightEdge();
  }, [initBrightEdge]);

  const initLoadMoreTriggerView = useCallback(() => {
    if (
      !pageLoading &&
      !productListLoading &&
      productList &&
      productList.length > 0 &&
      lastPageNumber > 1
    ) {
      if (typeof adobe != 'object') {
        console.error('Adobe not loaded yet, cannot trigger view.');
      } else {
        if (adobe?.target?.triggerView) {
          adobe?.target?.triggerView('load_more');
        } else {
          console.error(
            "Adobe and/or Adobe.target undefined. Can't trigger view load_more "
          );
        }
      }
    }
  }, [pageLoading, productListLoading, productList, lastPageNumber]);

  useEffect(() => {
    initLoadMoreTriggerView();
  }, [initLoadMoreTriggerView]);

  return (
    <>
      <PageHeader
        showBreadcrumbs
        pageTitle={pageTitle}
        breadcrumbType={BreadcrumbType.Category}
        {...(Boolean(pageHeaderEspot) && { espotId: pageHeaderEspot })}
        {...(brandImage && { headerImg: brandImage })}
        {...(collectionImage && { headerImg: collectionImage })}
        {...(brandImage && { xtrahigh: true })}
        {...((isBrandPlp || brandNames || isCollectionPlp) && {
          brandHeader: pageTitle,
        })}
      />

      {plpEspot1AId && <NteEspot espotId={plpEspot1AId} className='page-row' />}

      {!isBrandPlp && !isDealsPage && (
        <CategoryCarousel
          title={categoryCarouselTitle ? categoryCarouselTitle : pageTitle}
          categoryID={currentCategoryId}
        />
      )}

      <section className='page-row nav-left-content'>
        {windowSize.width >= 900 ? (
          <Hidden xsDown>
            <div
              role='region'
              aria-label='Product filter'
              className='facets left'>
              <FacetDesktop />
            </div>
          </Hidden>
        ) : (
          <Hidden smDown>
            <div
              role='region'
              aria-label='Product filter'
              className='facets left'>
              <FacetDesktop />
            </div>
          </Hidden>
        )}

        <div className='right'>
          <div role='group' className='product-list plp_layout'>
            {plpEspot2Id && (
              <NteEspot espotId={plpEspot2Id} className='section-row' />
            )}

            <ProductCards
              activeSortIndex={activeSortIndex}
              updateSortIndex={updateSortIndex}
              totalProducts={totalProducts}
              productList={productList}
            />
          </div>

          {!pageLoading && (
            <Pagination
              totalProducts={totalProducts}
              isPageLoading={productListLoading}
              currentPageNumber={lastPageNumber}
              updatePageNumber={updatePageNumber}
            />
          )}
        </div>
      </section>

      {<PlpFeedback />}

      {showProductComparisonBar && <ProductComparisonBar />}

      {seoSubHeadEspot && (
        <div className='page-row'>
          <NteEspot espotId={seoSubHeadEspot} />
        </div>
      )}

      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: brightEdgeHeadString,
          }}></div>
        <div
          id='be-ix-link-block'
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(brightEdgeString),
          }}></div>
      </div>

      <Rvi />

      <MiniCartDrawer />
    </>
  );
};

export { PlpLayout };
