const ShippingInfoDrawerConstants = {
  SHIPPING_OPTIONS: 'SHIPPING_INFO_DRAWER.SHIPPING_OPTIONS_TITLE',
  LIFT_GATE: 'SHIPPING_INFO_DRAWER.LIFT_GATE_TITLE',
  LIFT_GATE_MESSAGE_WARNING: 'SHIPPING_INFO_DRAWER.LIFT_GATE_MESSAGE_WARNING',
  LIFT_GATE_MESSAGE: 'SHIPPING_INFO_DRAWER.LIFT_GATE_MESSAGE',
  LIFT_GATE_MESSAG2: 'SHIPPING_INFO_DRAWER.LIFT_GATE_MESSAG2',
  LIFT_GATE_MESSAGE_CHOOSE: 'SHIPPING_INFO_DRAWER.LIFT_GATE_MESSAGE_CHOOSE',
};

export { ShippingInfoDrawerConstants };
