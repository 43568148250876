import { AxiosRequestConfig } from 'axios';
import { trackUserEvent } from '../../../utils/lucky-orange';
import { getLocalStorage } from '../../../utils/utils';
import { USER } from '../../constants/cookie';
import {
  AddToCartResponse,
  IAddGiftCard,
  IAddtoCart,
  ICatalogOrder,
  ICheckout,
  IDeleteItemInCart,
  IDeletePaymentInfo,
  IFetchGiftCardBalance,
  IFetchUsablePaymentInfo,
  IGetCartDetails,
  IOrderConfirmation,
  IPoNumber,
  IRemoveGiftCard,
  IShippingInfoRequest,
  IUpdateCartDetails,
  IUpdatePaymentInfo,
  IUpdateShippingAndBillingAddress,
} from '../../interface/Cart/IOrder';
import ICartResponse from '../../interface/Responses/ICartResponse';
import IShippingInfoResponse from '../../interface/Responses/IShippingInfoResponse';
import { GuestService } from '../auth/guest.service';
import { makeRequest } from '../axios/axiosConfig';
import { CartServiceConstants } from './cart.service.constants';

const CartService = {
  async addToCart({
    storeID,
    quantityParam,
    partNumber,
    WCTokenDetail,
    WCTrustedTokenDetail,
    comment,
    isMultipleOrder,
    multipleAddToCartPayload,
    history,
  }: IAddtoCart): Promise<AddToCartResponse> {
    const { ADD_TO_CART_URL, DEFAULT_QUANTITY } = CartServiceConstants;

    const addToCartPayload = {
      orderId: '.',
      x_calculateOrder: '1',
      orderItem: [
        {
          quantity: quantityParam ? quantityParam : DEFAULT_QUANTITY,
          partNumber: partNumber,
          ...{ ...(comment && { comment }) },
        },
      ],
      x_inventoryValidation: true,
      x_calculationUsage: '-1,-2,-7',
    };

    try {
      const addToCartRequest: AxiosRequestConfig = {
        url: ADD_TO_CART_URL(storeID),
        ...{
          ...(WCTokenDetail &&
            WCTrustedTokenDetail && {
              headers: {
                WCToken: WCTokenDetail,
                WCTrustedToken: WCTrustedTokenDetail,
              },
            }),
        },
        method: 'POST',
        data: isMultipleOrder ? multipleAddToCartPayload : addToCartPayload,
      };

      const addToCartResponse: AddToCartResponse = await makeRequest(
        addToCartRequest,
        undefined,
        history
      );

      return addToCartResponse;
    } catch (e) {
      throw e;
    }
  },

  async updateCartDetails({
    storeID,
    orderItemId,
    partNumber,
    quantity,
    history,
  }: IUpdateCartDetails): Promise<AddToCartResponse> {
    const { UPDATE_CART_DETAILS } = CartServiceConstants;

    const updateCartDetailsPayload = {
      orderId: '.',
      x_calculateOrder: '1',
      orderItem: [
        {
          orderItemId: orderItemId,
          quantity: quantity,
          partNumber: partNumber,
        },
      ],
      x_inventoryValidation: true,
      x_calculationUsage: '-1,-2,-7',
    };

    try {
      const updateCartDetailsRequest: AxiosRequestConfig = {
        url: UPDATE_CART_DETAILS(storeID),
        method: 'PUT',
        data: updateCartDetailsPayload,
      };

      const updateCartDetailsResponse: AddToCartResponse = await makeRequest(
        updateCartDetailsRequest,
        undefined,
        history
      );

      return updateCartDetailsResponse;
    } catch (e) {
      throw e;
    }
  },

  async deleteItemInCart({
    storeID,
    orderItemId,
    history,
  }: IDeleteItemInCart): Promise<any> {
    const deleteItemInCartPayload = {
      orderItemId: orderItemId,
      calculateOrder: '1',
      calculationUsage: '-1,-2,-7',
    };

    const { DELETE_ITEM_IN_CART } = CartServiceConstants;

    try {
      const deleteItemInCartRequest: AxiosRequestConfig = {
        url: DELETE_ITEM_IN_CART(storeID),
        method: 'PUT',
        data: deleteItemInCartPayload,
      };

      const deleteItemInCartResponse: AddToCartResponse = await makeRequest(
        deleteItemInCartRequest,
        undefined,
        history
      );

      return deleteItemInCartResponse;
    } catch (e) {
      throw e;
    }
  },

  async getCartDetails({
    storeID,
    WCTokenDetail,
    WCTrustedTokenDetail,
    isCartPage,
    history,
  }: IGetCartDetails): Promise<ICartResponse> {
    const { GET_CART_DETAILS } = CartServiceConstants;
    const gcLimitExceededFlag = sessionStorage.getItem('gcLimitExceeded');

    try {
      const getCartDetailsRequest: AxiosRequestConfig = {
        url: GET_CART_DETAILS(storeID, Boolean(isCartPage), gcLimitExceededFlag?Boolean(gcLimitExceededFlag):false),
        ...{
          ...(WCTokenDetail &&
            WCTrustedTokenDetail && {
              headers: {
                WCToken: WCTokenDetail,
                WCTrustedToken: WCTrustedTokenDetail,
              },
            }),
        },
      };

      const getCartDetailsResponse: ICartResponse = await makeRequest(
        getCartDetailsRequest,
        undefined,
        history
      );

      if(!getCartDetailsResponse.gcLimitExceeded){
        sessionStorage.removeItem('gcLimitExceeded');
      }

      return getCartDetailsResponse;
    } catch (e) {
      throw e;
    }
  },

  async guestAndCartInfo({
    storeID,
    partNumber,
    comment,
    quantityParam,
    isMultipleOrder,
    multipleAddToCartPayload,
    isCartPage,
    history,
  }: IAddtoCart) {
    try {
      const userCookieresponse = getLocalStorage(USER);

      if (!userCookieresponse) {
        const guestResponse = await GuestService.fetchGuestUser({ storeID });

        const { WCToken, WCTrustedToken } = guestResponse;

        const guestIdentityResponse = guestResponse;

        await CartService.addToCart({
          storeID,
          partNumber,
          WCTokenDetail: WCToken,
          WCTrustedTokenDetail: WCTrustedToken,
          comment,
          quantityParam,
          isMultipleOrder,
          multipleAddToCartPayload,
          history,
        });

        const fetchCartDetailsresponse = await CartService.getCartDetails({
          storeID,
          WCTokenDetail: WCToken,
          WCTrustedTokenDetail: WCTrustedToken,
          isCartPage,
          history,
        });

        return { guestIdentityResponse, fetchCartDetailsresponse };
      }
      await CartService.addToCart({
        storeID,
        partNumber,
        comment,
        quantityParam,
        isMultipleOrder,
        multipleAddToCartPayload,
        history,
      });

      const fetchCartDetailsresponse = await CartService.getCartDetails({
        storeID,
        isCartPage,
      });

      return { fetchCartDetailsresponse };
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method guestAndCatalogOrder
   * Responsible to do bulk order -- both for guest as well as other users
   */
  async guestAndCatalogOrder({ storeID, catalogOrder }: ICatalogOrder) {
    try {
      const userCookieresponse = getLocalStorage(USER);

      if (!userCookieresponse) {
        const guestResponse = await GuestService.fetchGuestUser({ storeID });

        const { WCToken, WCTrustedToken } = guestResponse;

        const guestIdentityResponse = guestResponse;

        const catalogOrderResponse = await CartService.catalogOrder({
          storeID,
          catalogOrder,
          WCTokenDetail: WCToken,
          WCTrustedTokenDetail: WCTrustedToken,
        });

        return {
          guestIdentityResponse,
          catalogOrderResponse,
        };
      }

      const catalogOrderResponse = await CartService.catalogOrder({
        storeID,
        catalogOrder,
      });

      return { catalogOrderResponse };
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method getShippingInfo Fetches the shipping information for a specific product.
   *
   * @param IShippingInfoRequest
   */
  async getShippingInfo({
    orderId,
    physicalStoreId,
    storeID,
    history,
  }: IShippingInfoRequest): Promise<IShippingInfoResponse> {
    try {
      const { GET_SHIPPING_INFO } = CartServiceConstants;

      const shippingInfoRequest: AxiosRequestConfig = {
        url: GET_SHIPPING_INFO(
          storeID,
          physicalStoreId,
          orderId ? orderId : ''
        ),
      };

      const shippingInfoResponse: IShippingInfoResponse = await makeRequest(
        shippingInfoRequest,
        undefined,
        history
      );

      return shippingInfoResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method updatePaymentInfo Update the payment billing information for checkout.
   *
   * @param updatePaymentInfo
   */
  async updatePaymentInfo({
    billingAddressId,
    storeId,
    paymentAmount,
    paymentDetails,
    history,
  }: IUpdatePaymentInfo): Promise<any> {
    try {
      const { UPDATE_PAYMENT_INFO } = CartServiceConstants;

      const updatePaymentInfoRequest: AxiosRequestConfig = {
        url: UPDATE_PAYMENT_INFO(storeId),
        method: 'POST',
        data: {
          billing_address_id: billingAddressId,
          piAmount: paymentAmount,
          ...paymentDetails,
        },
      };

      const updatedPaymentInfoResponse = await makeRequest(
        updatePaymentInfoRequest,
        undefined,
        history
      );
      return updatedPaymentInfoResponse;
    } catch (e) {
      trackUserEvent({
        eventMsg: e,
        eventName: 'paymentPage-paymentInstructionPost',
      });

      throw e;
    }
  },

  /**
   * @method deletePaymentInfo Deletes the payment instruction array from the cart.
   *
   * @param IDeletePaymentInfo
   */
  async deletePaymentInfo({
    storeId,
    history,
  }: IDeletePaymentInfo): Promise<void> {
    try {
      const { UPDATE_PAYMENT_INFO } = CartServiceConstants;

      const deletePaymentRequest: AxiosRequestConfig = {
        url: UPDATE_PAYMENT_INFO(storeId),
        method: 'DELETE',
      };

      const response = await makeRequest(
        deletePaymentRequest,
        undefined,
        history
      );
      return response;
    } catch (e) {
      trackUserEvent({
        eventMsg: e,
        eventName: 'paymentPage-paymentInstructionDelete',
      });

      throw e;
    }
  },

  async addGiftCard({
    billingAddressId,
    paymentAmount,
    storeId,
    giftCardNumber,
    giftCardPin,
    history,
  }: IAddGiftCard): Promise<any> {
    try {
      const { UPDATE_PAYMENT_INFO } = CartServiceConstants;

      const addGiftCardRequest: AxiosRequestConfig = {
        url: UPDATE_PAYMENT_INFO(storeId),
        method: 'POST',
        data: {
          ...{
            ...(billingAddressId && { billing_address_id: billingAddressId }),
          },
          piAmount: paymentAmount,
          account: giftCardNumber,
          gc_pin: giftCardPin,
          payMethodId: 'GiftCard',
          isBopisOrder: 'false',
        },
      };

      const addGiftCardResponse = await makeRequest(
        addGiftCardRequest,
        undefined,
        history
      );

      return addGiftCardResponse;
    } catch (e) {
      trackUserEvent({
        eventMsg: e,
        eventName: 'paymentPage-paymentInstructionAddGiftCardPost',
      });

      throw e;
    }
  },

  async removeGiftCard({
    storeId,
    paymentInstructionId,
    history,
  }: IRemoveGiftCard): Promise<any> {
    const { REMOVE_PAYMENT_INFO } = CartServiceConstants;

    try {
      const removeGiftCardRequest: AxiosRequestConfig = {
        url: REMOVE_PAYMENT_INFO(storeId, paymentInstructionId),
        method: 'DELETE',
      };

      const removeGiftCardResponse = await makeRequest(
        removeGiftCardRequest,
        undefined,
        history
      );

      return removeGiftCardResponse;
    } catch (e) {
      trackUserEvent({
        eventMsg: e,
        eventName: 'paymentPage-paymentInstructionGiftCardDelete',
      });

      throw e;
    }
  },

  async fetchGiftCardBalance({
    storeId,
    giftCardNumber,
    giftCardPin,
    history,
  }: IFetchGiftCardBalance): Promise<any> {
    try {
      const { GIFT_CARD_BALANCE_INFO } = CartServiceConstants;

      const giftCardBalanceRequest: AxiosRequestConfig = {
        url: GIFT_CARD_BALANCE_INFO(storeId),
        method: 'POST',
        data: {
          giftCardNum: giftCardNumber,
          giftCardPin: giftCardPin,
        },
      };

      const giftCardBalanceResponse = await makeRequest(
        giftCardBalanceRequest,
        undefined,
        history
      );

      return giftCardBalanceResponse;
    } catch (e) {
      throw e;
    }
  },

  async updateShippingAndBillingInfo({
    storeID,
    orderId,
    addressId,
    shipModeId,
    shippingCharge,
    x_liftGateChargesApplied,
    carrierEstimateDelivery,
    freightDiscount,
    x_sameDayDeliveryStore,
    x_useDefaultOption,
    x_orderItemEstimateDelivery,
    x_surcharge,
    x_iomStoreId,
    x_freightCredit,
    x_fulfillmentCenter,
    x_lineItems,
    x_iom,
    x_orderProcessingChannel,
    x_shippingMode,
    x_orderPromiseDateRange,
    history,
    x_carrier,
    x_code,
    x_carrierCode,
  }: IUpdateShippingAndBillingAddress): Promise<any> {
    const updatePayload = {
      orderId: '.',
      shipModeId,
      addressId,
      x_calculationUsage: x_surcharge ? '-1,-2,-6,-7' : '-1,-2,-7',
      x_calculateOrder: '1',
      x_allocate: '***',
      x_backorder: '***',
      x_check: '*n',
      x_remerge: '***',
      x_shippingCharge: shippingCharge,
      x_liftGateChargesApplied,
      x_carrierEstimateDelivery: carrierEstimateDelivery,
      x_freightDiscount: freightDiscount,
      x_sameDayDeliveryStore,
      x_useDefaultOption,
      x_orderItemEstimateDelivery,
      x_surcharge,
      x_iomStoreId,
      x_freightCredit,
      x_fulfillmentCenter,
      x_lineItems,
      x_iom,
      x_orderProcessingChannel,
      x_shippingMode,
      x_orderPromiseDateRange,
      x_carrier,
      x_code,
      x_carrierCode,
    };

    const { UPDATE_SHIPPING_INFO } = CartServiceConstants;

    try {
      const updateShippingAndBillingInfoRequest: AxiosRequestConfig = {
        url: UPDATE_SHIPPING_INFO(storeID),
        method: 'PUT',
        data: updatePayload,
      };

      const updateShippingAndBillingInfoRequestResponse: AddToCartResponse =
        await makeRequest(
          updateShippingAndBillingInfoRequest,
          undefined,
          history
        );

      return updateShippingAndBillingInfoRequestResponse;
    } catch (e) {
      trackUserEvent({
        eventMsg: e,
        eventName: 'shippingInfoPost',
      });

      throw e;
    }
  },

  async fetchUsablePaymentInfo({
    storeID,
    history,
  }: IFetchUsablePaymentInfo): Promise<any> {
    const { FETCH_USABLE_PAYMENT_INFO } = CartServiceConstants;

    try {
      const usablePaymentInfoRequest: AxiosRequestConfig = {
        url: FETCH_USABLE_PAYMENT_INFO(storeID),
        method: 'GET',
      };

      const usablePaymentInfoResponse: any = await makeRequest(
        usablePaymentInfoRequest,
        undefined,
        history
      );

      return usablePaymentInfoResponse;
    } catch (e) {
      trackUserEvent({
        eventMsg: e,
        eventName: 'paymentPage-usablePaymentInfo',
      });

      throw e;
    }
  },

  async giftCardPaymentInfo({
    storeID,
    billing_address_id,
    payMethodId,
    piId,
    history,
  }: any): Promise<any> {
    const { GIFT_CARD_PAYMENT_BILLING_ADDRESS_UPDATE_URL } =
      CartServiceConstants;

    const giftCardPayload = {
      billing_address_id,
      payMethodId,
      piId,
    };

    try {
      const giftCardPaymentInfoRequest: AxiosRequestConfig = {
        url: GIFT_CARD_PAYMENT_BILLING_ADDRESS_UPDATE_URL(storeID),
        method: 'PUT',
        data: giftCardPayload,
      };

      const giftCardPaymentInfoResponse: any = await makeRequest(
        giftCardPaymentInfoRequest,
        undefined,
        history
      );

      return giftCardPaymentInfoResponse;
    } catch (e) {
      trackUserEvent({
        eventMsg: e,
        eventName: 'paymentPage-paymentInstructionGiftCardPut',
      });

      throw e;
    }
  },

  async preCheckoutinfo({ storeID, history }: ICheckout): Promise<any> {
    const { PRECHECKOUT } = CartServiceConstants;

    try {
      const preCheckoutInfoRequest: AxiosRequestConfig = {
        url: PRECHECKOUT(storeID),
        method: 'PUT',
      };

      const precheckoutResponse: any = await makeRequest(
        preCheckoutInfoRequest,
        undefined,
        history
      );

      return precheckoutResponse;
    } catch (e) {
      trackUserEvent({ eventMsg: e, eventName: 'reviewPage-precheckout' });

      throw e;
    }
  },

  async checkoutInfo({
    storeID,
    orderId,
    email,
    forterTokenCookie,
    history,
  }: ICheckout): Promise<any> {
    const { CHECKOUT, ORDER_CONFIRMATION_PAYLOAD } = CartServiceConstants;

    try {
      const checkoutInfoInfoRequest: AxiosRequestConfig = {
        url: CHECKOUT(storeID),
        method: 'POST',
        data: {
          ...ORDER_CONFIRMATION_PAYLOAD,
          orderId,
          notify_EMailSender_recipient: email,
        },
        withCredentials: true,
      };

      const checkoutInfoResponse: any = await makeRequest(
        checkoutInfoInfoRequest,
        {
          Cookie: forterTokenCookie,
        },
        history
      );

      return checkoutInfoResponse;
    } catch (e) {
      trackUserEvent({ eventMsg: e, eventName: 'reviewPage-checkout' });

      throw e;
    }
  },

  /**
   * @method catalogOrder
   * Responsible to do bulk order
   *  Need to remove any and add appropriate promise while handling error scenario
   */
  async catalogOrder({
    storeID,
    catalogOrder,
    WCTokenDetail,
    WCTrustedTokenDetail,
  }: ICatalogOrder): Promise<any> {
    const { CATALOG_ORDER_URL } = CartServiceConstants;

    try {
      const catalogOrderRequest: AxiosRequestConfig = {
        url: CATALOG_ORDER_URL(storeID),
        ...{
          ...(WCTokenDetail &&
            WCTrustedTokenDetail && {
              headers: {
                WCToken: WCTokenDetail,
                WCTrustedToken: WCTrustedTokenDetail,
              },
            }),
        },
        method: 'POST',
        data: catalogOrder,
      };

      const catalogOrderResponse = await makeRequest(catalogOrderRequest);

      return catalogOrderResponse;
    } catch (e) {
      throw e;
    }
  },

  async getOrderConfirmationDetails({
    storeID,
    orderId,
    history,
  }: IOrderConfirmation): Promise<any> {
    const { ORDER_CONFIRMATION } = CartServiceConstants;

    try {
      const orderConfirmationRequest: AxiosRequestConfig = {
        url: ORDER_CONFIRMATION(storeID, orderId),
        method: 'GET',
      };

      const orderConfirmationResponse: any = await makeRequest(
        orderConfirmationRequest,
        undefined,
        history
      );

      return orderConfirmationResponse;
    } catch (e) {
      throw e;
    }
  },

  async updatePoNumberDetails({
    storeID,
    orderId,
    poNumber,
    history,
  }: IPoNumber): Promise<any> {
    const { PO_NUMBER_URL } = CartServiceConstants;

    try {
      const poNumberDetailRequest: AxiosRequestConfig = {
        url: PO_NUMBER_URL(storeID),
        method: 'POST',
        data: {
          poNumber: poNumber,
          orderId: orderId,
        },
      };

      const poNumberDetailResponse: any = await makeRequest(
        poNumberDetailRequest,
        undefined,
        history
      );

      return poNumberDetailResponse;
    } catch (e) {
      throw e;
    }
  },

  /**
   * @method orderCalculate makes a post request to assign the selected pickup store
   * @param storeID
   */
  async orderCalculate({
    storeID,
    history,
  }: IFetchUsablePaymentInfo): Promise<any> {
    const { ORDER_CALCULATE_URL } = CartServiceConstants;

    try {
      const orderCalculateRequest: AxiosRequestConfig = {
        url: ORDER_CALCULATE_URL(storeID),
        method: 'POST',
        data: {
          calculationUsageId: [-1, -2, -3, -4, -5, -7],
        },
      };

      const orderCalculateResponse = await makeRequest(
        orderCalculateRequest,
        undefined,
        history
      );

      return orderCalculateResponse;
    } catch (error) {
      trackUserEvent({
        eventName: 'deliveryPage-calculatePost',
        eventMsg: error,
      });

      throw error;
    }
  },
};

export { CartService };
