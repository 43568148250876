import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StringParam, useQueryParam } from 'use-query-params';
import { UserType } from '../../../../../_foundation/enum/User/UserType';
import { useSite } from '../../../../../_foundation/hooks/usesite/useSite';
import {
  IProductListFacets,
  IProductListFacetsEntry,
  ISelectedFacetGroups,
} from '../../../../../_foundation/interface/ProductList/IProductList';
import {
  SET_FACET_FROM_PARAMS_ACTION,
  SHOW_PLP_META_TAGS_ACTION,
} from '../../../../../redux/actions/productList.actions';
import { authenticationSelector } from '../../../../../redux/selectors/auth.selector';
import { productListSelector } from '../../../../../redux/selectors/productList.selector';
import { useCurrentPathName } from '../../../../../utils/hooks/current-pathname/CurrentPathName';
import {
  formatQueryParams,
  formatToTitleCase,
  removeQueryParamBackslash,
} from '../../../../../utils/utils';
import { seoSelector } from '../../../../Seo/redux/selector/seo';
import { FacetConstants } from '../FacetConstants';
import { useAppendFacetQuery } from './AppendFacetQueryHooks';
import { useHistory } from 'react-router';
import { PaymentServiceConstants } from '../../../../../_foundation/apis/payment/payment.service.constants';
import { PriceFacetConstants } from '../PriceFacet/PriceFacetConstants';
import { storeLocatorSelector } from '../../../../../redux/selectors/storeLocator';

/**
 * @method useFacets
 * Fetches the facets from the redux memory.
 * Seggregates the brand facets as private and non private brands.
 * Updates the url query param on selection of more than 3 brands.
 */
const useFacets = () => {
  const {
    BRAND_FACET_KEY,
    FACET_QUERY_KEY,
    SEO_QUERY_KEY,
    SEO_QUERY_VALUE,
    PRICE_RANGE,
    PRICE,
    PARENT_CATALOG_GROUP,
    PROMOTION,
    ADVANTAGE_EXCLUSIVE,
    CATEGORY,
    CATEGORY_PAGE,
    ALL_DEALS_LABEL,
    ALL_DEALS_FACET_VALUE,
    COLLECTIONS,
  } = FacetConstants;

  const dispatch = useDispatch();

  const {
    facets,
    selectedFacets,
    loading,
    privateBrands,
    selectedGroups,
    facetLoading,
  } = useSelector(productListSelector);

  const seoConfig = useSelector(seoSelector);

  const history = useHistory();

  const { mySite } = useSite();

  const { currentPathName } = useCurrentPathName();

  const { userType } = useSelector(authenticationSelector);

  const addMetaTags = selectedFacets.length >= 3;

  const [facetValues, setFacetValues] = useState<IProductListFacets[]>(facets);

  const { storeDetails } =
    useSelector(storeLocatorSelector);

  const { SOLD_IN_STORES } = PriceFacetConstants;

  // Format the current path "/categories/power-tools" as PowerTools
  const formattedPath = formatToTitleCase(
    currentPathName.replace(/-/g, ' ')
  ).replace(/ /g, '');

  const isBrandPlp = Boolean(seoConfig[formattedPath]?.isBrandPlp);

  /**
   * useQueryParam handles the history out of the box.
   *
   * facetsParams reads the query param values that belongs to the key "f"
   * setFacetsParam can be used to set a specific facet param for the key "f"
   */
  const [encodedFacetParams] = useQueryParam(FACET_QUERY_KEY, StringParam);

  const [, setSeoParams] = useQueryParam(SEO_QUERY_KEY, StringParam);

  const { appendQueryParams } = useAppendFacetQuery();

  const facetsParams = encodedFacetParams
    ? removeQueryParamBackslash(encodedFacetParams)
    : '';

  /**
   * @method initBrandFacets Seggregates the private brands to the top and sorts
   * them in descending order based on product count and seggregates the national
   * brands after private brands and sorts them in descending order based
   * on the product count.
   */
  const initBrandFacets = useCallback((): void => {
    if (!loading) {
      const brandsIndex = facets.findIndex(
        (facet) => facet.name === BRAND_FACET_KEY
      );

      if (brandsIndex !== -1) {
        /**
         * Filter out the brandFacets from facets
         * and filter out the brands that has a
         * valid label.
         */
        const brandsFacets = facets[brandsIndex].entry.filter(
          ({ label }) => label?.length > 0
        );

        // Filter out all the private brands name.
        const privateBrandNames = privateBrands.map(
          ({ brandName }) => brandName
        );

        const filteredPrivateBrands: IProductListFacetsEntry[] = [];

        const nonPrivateBrands: IProductListFacetsEntry[] = [];

        brandsFacets.map((brands: IProductListFacetsEntry) => {
          if (privateBrandNames.indexOf(brands.label) !== -1) {
            // Filter private brands from the brands facets.
            filteredPrivateBrands.push(brands);
          } else {
            // Filter non private from the brands facets.
            nonPrivateBrands.push(brands);
          }

          return '';
        });

        // Sort private brands based on product count in descending order.
        const sortedPrivateBrands = [...filteredPrivateBrands].sort((a, b) =>
          a.count < b.count ? 1 : -1
        );

        // Sort non private brands based on product count in descending order.
        const sortedNonPrivateBrands = [...nonPrivateBrands].sort((a, b) =>
          a.count < b.count ? 1 : -1
        );

        // Construct newly updated brandfacets.
        const updatedBrandsFacets = [
          ...sortedPrivateBrands,
          ...sortedNonPrivateBrands,
        ];

        let updatedFacets = [...facets];

        // Update the brands facets inside the facets.
        updatedFacets[brandsIndex] = {
          ...updatedFacets[brandsIndex],
          entry: updatedBrandsFacets,
        };

        setFacetValues(updatedFacets);
      } else {
        setFacetValues(facets);
      }
    }
  }, [BRAND_FACET_KEY, facets, loading, privateBrands]);

  /**
   * @method validatePromotionFacet Removes the Promotion and collection facets
   * Removes the Promotion Facet if the only facet value is 'Advantage Exclusives'
   * and the user is not an advantage user. Removes collections facets from Category pages
   */
  const validatePromotionFacet = useCallback((): void => {
    if (!loading && facets.length !== 0) {
      const promotionFacet = facets.find(
        ({ name }) => name.toLowerCase() === PROMOTION.toLowerCase()
      );

      if (promotionFacet) {
        const hasAdvantageExclusives = promotionFacet.entry.find(
          ({ label }) =>
            label.toLowerCase() === ADVANTAGE_EXCLUSIVE.toLowerCase()
        );

        if (
          hasAdvantageExclusives &&
          promotionFacet.entry.length === 1 &&
          userType !== UserType.Advantage
        ) {
          const updatedFacets = facets.filter(
            ({ name }) => name.toLowerCase() !== PROMOTION.toLowerCase()
          );

          setFacetValues(updatedFacets);
        }
      }

      let isCategoryPage: boolean =
        history.location.pathname.includes(CATEGORY_PAGE);

      const collectionFacet = facets.find(
        ({ name }) => name.toLowerCase() === COLLECTIONS.toLowerCase()
      );

      if (collectionFacet && isCategoryPage) {
        const updatedFacets = facets.filter(
          ({ name }) => name.toLowerCase() !== COLLECTIONS.toLowerCase()
        );
        setFacetValues(updatedFacets);
      }
    }
  }, [
    ADVANTAGE_EXCLUSIVE,
    PROMOTION,
    facets,
    loading,
    userType,
    COLLECTIONS,
    history.location.pathname,
    CATEGORY_PAGE,
  ]);

  useEffect(() => {
    initBrandFacets();
  }, [initBrandFacets, loading, facets]);

  useEffect(() => {
    validatePromotionFacet();
  }, [validatePromotionFacet, loading, facets]);

  useEffect(() => {
    appendQueryParams();
  }, [selectedGroups, appendQueryParams]);

  /**
   * @callback constructFacetsFromParam Constructs the facets groups for params.
   *
   * Example:
   * Brand: A_P_E|Arcan|Blackline => { "Brand": [{ label: "APE", ... }, { label: "Arcan", ... }] }
   */
  const constructFacetsFromParam = useCallback(
    (facetParams: any): ISelectedFacetGroups => {
      /**
       * Removes the delimiter from the A_P_E|Arcan|Blackline facets label.
       *
       * Example: A_P_E|Arcan|Blackline => ["A_P_E", "Arcan", "Blackline"]
       */
      const facetLabels = formatQueryParams('|').remove(facetParams[1]);

      /**
       * Replace "A_P_E" => APE
       */
      const facetGroup =
        facetParams[0]?.replace(/_/g, ' ') === CATEGORY
          ? PARENT_CATALOG_GROUP
          : facetParams[0]?.replace(/_/g, ' ') === 'Price'
          ? 'OfferPrice_usd'
          : facetParams[0]?.replace(/_/g, ' ');
      const facetValues = facets.find(
        ({ name }) => name.toLowerCase() === facetGroup.toLowerCase()
      );

      const selectedFacetValues: IProductListFacetsEntry[] = [];

      if (facetValues && mySite) {
        facetLabels?.forEach((facetLabel) => {
          const formattedLabel = facetLabel?.replace(/_/g, ' ');

          if (
            facetLabel?.toLowerCase() === ALL_DEALS_FACET_VALUE &&
            facetValues.name.toLowerCase() === PROMOTION.toLowerCase()
          ) {
            let allDealsCount = 0;

            facetValues.entry.forEach((facet) => {
              if (userType === UserType.Advantage) {
                allDealsCount += Number(facet.count);
              } else {
                if (
                  facet.label.toLowerCase() !==
                  ADVANTAGE_EXCLUSIVE.toLowerCase()
                ) {
                  allDealsCount += Number(facet.count);
                }
              }
            });

            const allDeals: IProductListFacetsEntry = {
              count: allDealsCount.toString(),
              extendedData: {
                uniqueId: '',
              },
              label: ALL_DEALS_LABEL,
              value:
                userType === UserType.Advantage
                  ? mySite.allDealsAdvantageFilter
                  : mySite.allDealsNonAdvantageFilter,
              isPriceFacet: false,
            };

            selectedFacetValues.push(allDeals);
          } else if(facetGroup?.toLowerCase() === SOLD_IN_STORES.toLowerCase()) {

            /*
              Changes made to support "Sold in Store" facet on PLP. With these changes in place and the facet is applied, 
              for mentioned below two secnarios, facet will be retained:
              1. On page refresh.
              2. Navigation to some other page from PLP and then pressing back button to comeback to same PLP.
            */
            const nearByStoreDetails = [...storeDetails];

            /*
              Iterating over nearby store data list for the first 5 elements
              (since only first 5 stores are displayed in sold in store facet list on web),
              within which, currentFacet object is fetched from available facetValue list based on 
              the nearby store unique id and facet lable (from facetValues) 
              along with current selected facet lable matching with store display name. 
              Once the current facet is identified, setting it to selectedFacetValues which is a redux variable.
            */

            nearByStoreDetails
            .splice(0,5)
              .every(({ Description, uniqueID }) => {
                  let currentFacet = facetValues.entry.find(
                    (value) => (uniqueID === value.label) && (Description[0]?.displayStoreName.toLowerCase() === formattedLabel?.toLowerCase())
                    );
                  
                  if(currentFacet){
                    currentFacet = {
                      ...currentFacet,
                      label: Description[0]?.displayStoreName
                    };
                    selectedFacetValues.push(currentFacet);
                    return false;
                  }
                  return true;
                }
              );

          } else {
            let facetValue = facetValues?.entry?.find((value) => {
              if (
                value?.label?.toLowerCase() === formattedLabel?.toLowerCase()
              ) {
                selectedFacetValues.push({
                  ...value,
                  label: formattedLabel || '',
                });
              }
              return (
                value?.label?.toLowerCase() === formattedLabel?.toLowerCase()
              );
            });

            if (facetValues?.name === 'OfferPrice_usd') {
              facetValue = facetValues?.entry?.find((value) => {
                // Extract and format numbers from value.label
                let formattedLabelValue =
                  value?.label
                    .match(/\d+\.?\d*/g) // Extract numbers including decimals
                    ?.map((num) => parseInt(num, 10)) // Convert to integers to ignore `.00`
                    .join('-') || '';
                // Extract and format numbers from formattedLabel
                let actualFormattedLabel =
                  formattedLabel
                    ?.match(/\d+\.?\d*/g) // Extract numbers including decimals
                    ?.map((num) => parseInt(num, 10)) // Convert to integers to ignore `.00`
                    .join('-') || '';

                // Compare the formatted numbers
                return formattedLabelValue === actualFormattedLabel;
              });
              let unformattedLabel = formattedLabel?.replace(
                /(\$|,|\.00)/g,
                ''
              );
              let facetLbl =
                unformattedLabel
                  ?.split('-')
                  .map((part) => part.trim().replace(/[a-zA-Z]/g, '')) || [];

              facetValue = facetValues?.entry?.find((value) => {
                if (facetLbl.length > 0) {
                  if (facetLbl.length < 2) {
                    if (value?.label?.includes(facetLbl[0].trim())) {
                      const updatedValue = {
                        ...value,
                        label: formattedLabel || '',
                      };
                      selectedFacetValues.push({
                        ...value,
                        label: formattedLabel || '',
                      });
                      return updatedValue;
                    }
                  } else {
                    if (value?.label?.includes(facetLbl[1])) {
                      const updatedValue = {
                        ...value,
                        label: formattedLabel || '',
                      };
                      selectedFacetValues.push({
                        ...value,
                        label: formattedLabel || '',
                      });
                      return updatedValue;
                    }
                  }
                }
              });
            }
            // if (facetValue) {
            //   selectedFacetValues.push(facetValue);
            // }
          }
        });
      }

      if (facetGroup) {
        return {
          [facetGroup === 'OfferPrice_usd' ? 'Price' : facetGroup]:
            selectedFacetValues,
        };
      }

      return {};
    },
    [
      CATEGORY,
      PARENT_CATALOG_GROUP,
      facets,
      mySite,
      ALL_DEALS_FACET_VALUE,
      PROMOTION,
      ALL_DEALS_LABEL,
      userType,
      ADVANTAGE_EXCLUSIVE,
    ]
  );

  /**
   * @callback decodeQueryParams Constructs facet groups from query params.
   */
  const decodeQueryParams = useCallback((): void => {
    if (facetsParams && !loading && !facetLoading) {
      let facetGroups: any = formatQueryParams(' ').remove(facetsParams);

      let selectedFacetGroups: ISelectedFacetGroups = {};

      const isSelectedFacetsAvailable =
        Object.keys(selectedGroups).length === 0 && selectedFacets.length === 0;

      if (
        facetGroups.length >= 1 &&
        (facetsParams.includes(PARENT_CATALOG_GROUP) ||
          facetsParams.includes(CATEGORY))
      ) {
        const currentFacetGroups = [...facetGroups];

        let currentCategoryFacet = '';

        let concatHalf = '';

        let existingCategoryIndex = -1;

        let clutterIndex = -1;

        currentFacetGroups.forEach((currentFacetGroup: string) => {
          if (currentFacetGroup.includes(CATEGORY)) {
            currentCategoryFacet = currentFacetGroup;
          }

          if (!currentFacetGroup.includes(':')) {
            concatHalf = currentFacetGroup;
          }
        });
        const categoryFacet = currentCategoryFacet
          ?.concat(concatHalf)
          .replace('__', ' * ');
        if (existingCategoryIndex !== -1 && clutterIndex !== -1) {
          currentFacetGroups.splice(existingCategoryIndex, 1);

          currentFacetGroups.splice(clutterIndex, 1);
        }

        currentFacetGroups.push(categoryFacet);

        const clutterValue = currentFacetGroups.findIndex(
          (currentFacetGroup: string) => !currentFacetGroup.includes(':')
        );

        currentFacetGroups.splice(clutterValue, 1);
        facetGroups = currentFacetGroups;
      }

      /**
       * Removes the "+" delimiter from the facet groups.
       *
       * Example:
       * Brand:A_P_E|Blackhawk_Automotive+New_Arrival:Y+Price_Range:23-233 => ["Brand:A_P_E|Blackhawk_Automotive", "New_Arrival:Y", "Price_Range:23-233"]
       */

      if (facetGroups.length > 1) {
        facetGroups = facetGroups[0].split('+');
      } else {
        facetGroups[0] = facetGroups[0].replace('_+_', ' * ');
        facetGroups = facetGroups[0].split('+');
      }

      facetGroups?.forEach((facetGroup: any) => {
        /**
         * Removes the ":" from the current facet group.
         *
         * Example: Brand:A_P_E|Blackhawk_Automotive => ["Brand", "A_P_E|Blackhawk_Automotive"]
         */
        facetGroup = facetGroup.replace(' * ', ' + ');
        const facetParams: (string | null)[] | null | undefined =
          formatQueryParams(':').remove(facetGroup);

        if (facetParams) {
          selectedFacetGroups = {
            ...selectedFacetGroups,
            ...constructFacetsFromParam(facetParams),
          };
        }
      });

      if (isSelectedFacetsAvailable) {
        dispatch(SET_FACET_FROM_PARAMS_ACTION(selectedFacetGroups));
      }
    }
  }, [
    CATEGORY,
    PARENT_CATALOG_GROUP,
    PRICE,
    PRICE_RANGE,
    constructFacetsFromParam,
    dispatch,
    facetLoading,
    facetsParams,
    loading,
    selectedFacets.length,
    selectedGroups,
  ]);

  useEffect(() => {
    decodeQueryParams();
  }, [decodeQueryParams, loading, facetLoading]);

  /**
   * @callback addSeoParam Adds Seo query param to the url.
   */
  const addSeoParam = useCallback(() => {
    if (addMetaTags) {
      dispatch(SHOW_PLP_META_TAGS_ACTION(addMetaTags));

      setSeoParams(SEO_QUERY_VALUE, 'replaceIn');
    } else {
      dispatch(SHOW_PLP_META_TAGS_ACTION(false));
      setSeoParams(undefined, 'replaceIn');
    }
  }, [SEO_QUERY_VALUE, addMetaTags, dispatch, setSeoParams]);

  useEffect(() => {
    addSeoParam();
  }, [addSeoParam]);

  /**
   * @callback filterBrandFacet Filters and removes the brands facet
   * from the facets incase of brands plp.
   */
  const filterBrandFacet = useCallback((): void => {
    if (isBrandPlp) {
      const currentFacetValues = [...facetValues];

      const brandFacetIndex = facetValues.findIndex(
        ({ name }) => name === BRAND_FACET_KEY
      );

      if (brandFacetIndex !== -1) {
        currentFacetValues.splice(brandFacetIndex, 1);

        setFacetValues(currentFacetValues);
      }
    }
  }, [BRAND_FACET_KEY, facetValues, isBrandPlp]);

  useEffect(() => {
    filterBrandFacet();
  }, [filterBrandFacet, facetValues]);

  return { facetValues, loading, addMetaTags };
};

export { useFacets };
