import { AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';
import { ErrorConstants } from '../../../components/Error/ErrorConstants';
import { SHOW_NTE_ALERT_ACTION } from '../../../components/NteAlert/actions/nte-alert.actions';
import { SessionInvalidConstants } from '../../../components/SessionInvalid/SessionInvalidConstants';
import i18n from '../../../i18n';
import { alertMessageId, is500SeriesError } from '../../../utils/utils';
import { AuthenticationService } from '../../../_foundation/apis/auth/authentication.service';
import { GuestService } from '../../../_foundation/apis/auth/guest.service';
import {
  GET_USER_CONTACT_INFO_SUCCEEDED_ACTION,
  GUEST_LOGIN_SUCCESS_ACTION,
  INVALID_USER_CREDENTIALS_ACTION,
  LOGIN_SUCCEEDED_ACTION,
  REGISTRATION_FAILED_ACTION,
  UPDATE_PASSWORD_FAILED_ACTION,
  UPDATE_PASSWORD_SUCCEEDED_ACTION,
  UPDATE_USER_REGISTRATION_ERROR_ACTION,
  UPDATE_USER_INFO_SUCCEEDED_ACTION,
  UPDATE_USER_REGISTRATION_SUCCESS_ACTION,
} from '../../actions/auth.actions';
import { SET_BUTTON_LOADING_ACTION } from '../../actions/button.actions';
import { RESET_NETWORK_ERROR_ACTION } from '../../actions/network-errors.actions';
import { FETCH_CART_DETAILS_REQUEST_ACTION } from '../../actions/order.actions';
import { IUserInfo } from '../../../_foundation/interface/Authentication/IAuth';

const translationKey = 'API_ERROR_HANDLING.MY_ACCOUNT.ACCOUNT_PROFILE';
const SIGNED_IN_SUCCESSFULL =
  'ACCOUNT_SIGNIN.MESSAGE.SUCCESS.SIGNED_IN_SUCCESSFULL';

export function* login(action: any): Generator {
  try {
    if (action.payload.storeID) {
      const response: any = yield call(
        new AuthenticationService().authenticateUser,
        action.payload
      );

      yield put(LOGIN_SUCCEEDED_ACTION(response));

      yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));

      if (action.payload?.showSuccess) {
        yield put(
          SHOW_NTE_ALERT_ACTION({
            message: i18n.t(SIGNED_IN_SUCCESSFULL),
            messageId: alertMessageId(),
            severity: 'success',
          })
        );
      }

      yield put(RESET_NETWORK_ERROR_ACTION());

      yield put(
        FETCH_CART_DETAILS_REQUEST_ACTION({
          storeID: action.payload.storeID,
          signInProcessed: action.payload?.signInProcessed,
        })
      );

      if (action.payload.history && action.payload.redirectPath) {
        action.payload.history.push(action.payload.redirectPath);
      }
    } else {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: i18n.t(`${translationKey}.LOGIN_STOREID.ERROR`),
          messageId: alertMessageId(),
          severity: 'error',
        })
      );
    }
  } catch (e) {
    const axiosError: AxiosError = e as AxiosError;

    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));

    console.error(e);

    if (
      axiosError.response?.status === 404 ||
      axiosError.response?.status === 500
    ) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: i18n.t(`${translationKey}.LOGIN_IDENTITY.ERROR`),
          messageId: alertMessageId(),
          severity: 'error',
        })
      );

      return;
    }

    if (axiosError.response?.status === 400 && action.payload?.showSuccess) {
      let customErrorMessage = axiosError.response?.data.errors[0].errorMessage;

      if (
        (axiosError.code = SessionInvalidConstants.PASSWORD_EXPIRED_ERR_CODE)
      ) {
        customErrorMessage = ErrorConstants.ERROR_LOGIN_INCORRECT;
      }
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: i18n.t(customErrorMessage),
          messageId: alertMessageId(),
          severity: 'error',
        })
      );
    }

    yield put(INVALID_USER_CREDENTIALS_ACTION(axiosError));
  }
}

export function* getUserContactInformation(action: any): Generator {
  try {
    const response: any = yield call(
      new AuthenticationService().getUserContactInformation,
      action.payload
    );

    yield put(GET_USER_CONTACT_INFO_SUCCEEDED_ACTION(response));

    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));

    yield put(RESET_NETWORK_ERROR_ACTION());
  } catch (e) {
    console.error(e);

    const axiosError: AxiosError = e as AxiosError;

    yield put(INVALID_USER_CREDENTIALS_ACTION(axiosError));
  }
}

export function* getUserInfo(action: any): Generator {
  try {
    const response = yield call(
      new AuthenticationService().getUserInfo,
      action.payload
    );
    //added for fix 130720 order confirmation in paypal to update user info
    yield put(
      UPDATE_USER_INFO_SUCCEEDED_ACTION({ userInfo: response as IUserInfo })
    );
  } catch (e) {
    console.error(e);

    const axiosError: AxiosError = e as AxiosError;

    yield put(INVALID_USER_CREDENTIALS_ACTION(axiosError));
  }
}

export function* registerUser(action: any): Generator {
  try {
    const response: any = yield call(
      new AuthenticationService().registerUser,
      action.payload
    );

    yield put(LOGIN_SUCCEEDED_ACTION(response));

    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));

    yield put(RESET_NETWORK_ERROR_ACTION());

    yield put(
      FETCH_CART_DETAILS_REQUEST_ACTION({
        storeID: action.payload.storeID,
      })
    );
  } catch (e) {
    console.error(e);

    const axiosError: AxiosError = e as AxiosError;

    yield put(REGISTRATION_FAILED_ACTION(axiosError));
  }
}

export function* changeUserPassword(action: any): Generator {
  try {
    yield call(new AuthenticationService().changeUserPassword, action.payload);

    yield put(UPDATE_PASSWORD_SUCCEEDED_ACTION());
  } catch (e) {
    const axiosError: AxiosError = e as AxiosError;

    console.error(e);

    if (
      axiosError.response?.status === 404 ||
      axiosError.response?.status === 500
    ) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: i18n.t(`${translationKey}.CHANGE_PASSWORD.ERROR`),
          messageId: alertMessageId(),
          severity: 'error',
        })
      );

      return;
    }

    yield put(UPDATE_PASSWORD_FAILED_ACTION(axiosError));
  }
}

export function* getRecentOrders(action: any): Generator {
  try {
    const response: any = yield call(
      new AuthenticationService().getRecentOrders,
      action.payload
    );

    yield put(LOGIN_SUCCEEDED_ACTION(response));
  } catch (e) {
    console.error(e);

    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.RECENT_ORDERS.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );
  }
}

export function* addUpdateCustomerNumber(action: any): Generator {
  try {
    const response: any = yield call(
      new AuthenticationService().updateUserRegistrationDetails,
      action.payload
    );

    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));

    yield put(UPDATE_USER_REGISTRATION_SUCCESS_ACTION(response));
  } catch (e) {
    console.error(e);

    yield put(SET_BUTTON_LOADING_ACTION({ loading: false }));

    const axiosError: AxiosError = e as AxiosError;

    if (axiosError.response?.status === 404 || is500SeriesError(axiosError)) {
      yield put(
        SHOW_NTE_ALERT_ACTION({
          message: i18n.t(`${translationKey}.UPDATE_USER_INFORMATION.ERROR`),
          messageId: alertMessageId(),
          severity: 'error',
        })
      );
      return;
    }
    const { response } = axiosError;
    yield put(UPDATE_USER_REGISTRATION_ERROR_ACTION(response?.data));
  }
}

export function* guestLogin(action: any): Generator {
  try {
    const response = yield call(GuestService.fetchGuestUser, action.payload);

    yield put(GUEST_LOGIN_SUCCESS_ACTION(response));

    yield put(
      FETCH_CART_DETAILS_REQUEST_ACTION({
        storeID: action.payload.storeID,
      })
    );
  } catch (e) {
    console.error(e);

    yield put(
      SHOW_NTE_ALERT_ACTION({
        message: i18n.t(`${translationKey}.GUEST_IDENTITY.ERROR`),
        messageId: alertMessageId(),
        severity: 'error',
      })
    );
  }
}
