import HTMLReactParser from 'html-react-parser';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authenticationSelector } from '../../../../../../../redux/selectors/auth.selector';
import { categoriesSelector } from '../../../../../../../redux/selectors/categories.selector';
import { storeLocatorSelector } from '../../../../../../../redux/selectors/storeLocator';
import {
  extractEspotHtml,
  getRegionAdForStore,
} from '../../../../../../../utils/utils';
import { eSpotService } from '../../../../../../../_foundation/apis/transaction/espot/eSpot.service';
import { UserType } from '../../../../../../../_foundation/enum/User/UserType';
import { useSite } from '../../../../../../../_foundation/hooks/usesite/useSite';
import IEspotResponse from '../../../../../../../_foundation/interface/Responses/IEspotResponse';
import { StoreMenu } from '../../interface/StoreMenuInterface';
import { NavBarMenuConstants } from '../../NavBarMenuConstants';
import { DealsMenuItemConstants } from '../DealsMenuItemConstants';

/**
 * @hooks useDealsMenuItem is responsible for loading
 * menu items for the deals with respect to the user type.
 */
const useDealsMenuItem = () => {
  const { ADVANTAGE_DEALS_ESPOT, GUEST_DEALS_ESPOT } = DealsMenuItemConstants;

  const { DEALS } = NavBarMenuConstants;

  const { mySite } = useSite();

  const { currentStoreDetails, loading: storeLoading} = useSelector(storeLocatorSelector);

  const { userType } = useSelector(authenticationSelector);

  const { categoriesData } = useSelector(categoriesSelector);

  const [getDeals, setGetDeals] = useState(false);

  const [loading, setLoading] = useState<boolean>(true);

  const [dealsMenu, setDealsMenu] = useState<StoreMenu[]>();

  const [dealsHtml, setDealsHtml] = useState<
    string | JSX.Element | JSX.Element[]
  >(<></>);

  const showDealsMenu: boolean =
    userType === UserType.NotSignedIn ||
    userType === UserType.SignedIn ||
    userType === UserType.Advantage;

  /**
   * @method checkDeals Checks if the deals is available in the CMC.
   */
  const checkDeals = useCallback((): void => {
    const currentIndex = categoriesData.findIndex(
      (category) => category.identifier === DEALS
    );

    if(currentIndex > -1){
      setGetDeals(true);
    }else{
      categoriesData.length > 0 && setLoading(false);
    }
  }, [DEALS, categoriesData]);

  useEffect(() => {
    checkDeals();
  }, [checkDeals]);

  const constructMenuItem = (anchorElement: JSX.Element) => {
    const menuItem: StoreMenu = {
      children: [],
      name: `${anchorElement?.props?.children}`,
      identifier: `${anchorElement?.props?.children}`,
      seo: { href: anchorElement?.props?.href },
    };

    return menuItem;
  };

  /**
   * @callback parseDealsMenuContent Parses the link the deals html from CMC to use react
   * router links to preserve the single page application behaviour while navigation.
   */
  const parseDealsMenuContent = useCallback((content: any) => {
    const parsedReactContent: JSX.Element = HTMLReactParser(
      content
    ) as JSX.Element;

    const espotLinks: StoreMenu[] = [];

    /**
     * parsedReactContent contains the deals menu html as a JSX element object, we'll iterate the children of this
     * object to extract only the "li" tags and neglect any line breaks "\n", then we'll iterate the "li"s children to
     * extract the anchor element, once we extract the anchor element's name and the href we'll construct the react router
     * link and will substitute the name and the href to that link.
     */
    parsedReactContent.props.children.forEach(
      (element: JSX.Element, index: number) => {
        if (element?.type === 'li') {
          if (Array.isArray(element.props.children)) {
            element.props.children.forEach((anchorElement: JSX.Element) => {
              if (anchorElement?.type === 'a') {
                espotLinks.push(constructMenuItem(anchorElement));
              }
            });
          } else {
            if (element?.props?.children?.type === 'a') {
              espotLinks.push(constructMenuItem(element?.props?.children));
            }
          }
        }
      }
    );

    setDealsMenu(espotLinks);
  }, []);

  /**
   * @callback initDealsEspot Fetches the deals menu html from the BE
   * and initializes it to load links via react router.
   */
  const initDealsEspot = useCallback(async (): Promise<void> => {
    if (mySite && !storeLoading) {
      setLoading(true);

      const espotName =
        userType === UserType.Advantage
          ? ADVANTAGE_DEALS_ESPOT
          : userType === UserType.SignedIn || userType === UserType.NotSignedIn
          ? GUEST_DEALS_ESPOT
          : '';

      if (espotName) {
        try {
          const regionAd =
            currentStoreDetails && getRegionAdForStore(currentStoreDetails);

          const dealsEspot: IEspotResponse = await eSpotService.fetchEspotData({
            name: espotName,
            storeID: mySite.storeID,
            dmEspotRegion: regionAd,
          });

          const dealsEspotHtml: string = extractEspotHtml(dealsEspot);

          if (dealsEspotHtml) {
            parseDealsMenuContent(dealsEspotHtml.trim());
          } else {
            setDealsHtml(<></>);
          }

          setLoading(false);
        } catch (e) {
          console.error(e);

          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  }, [
    storeLoading,
    ADVANTAGE_DEALS_ESPOT,
    GUEST_DEALS_ESPOT,
    currentStoreDetails,
    mySite,
    parseDealsMenuContent,
    userType,
  ]);

  useEffect(() => {
    if (getDeals) {
      initDealsEspot();
    }
  }, [initDealsEspot, getDeals]);

  return { showDealsMenu, dealsMenu, dealsHtml, loading };
};

export { useDealsMenuItem };
