import { combineReducers } from 'redux';
import { ICheckoutState } from '../../components/Checkout/interface/ICheckoutState';
import { checkoutReducer } from '../../components/Checkout/redux/reducers/checkout.reducer';
import {
  menuListReducer,
  NteMenuListState,
} from '../../components/Menu/NteMenuList/redux/reducers/menu-list.reducer';
import { nteAlertReducers } from '../../components/NteAlert/reducers/nte-alert.reducers';
import { seoReducer } from '../../components/Seo/redux/reducers/seo';
import { widgetInfoReducer } from '../../components/Seo/redux/reducers/widget-info';
import { rviReducer } from '../../components/Widgets/Rvi/redux/reducer/rvi.reducer';
import { saveToListReducer } from '../../components/Widgets/SaveToListMenu/reducers/save-to-list.reducers';
import { ISearchHistoryState } from '../../components/Widgets/SearchHistory/interface/ISearchHistory';
import { searchHistoryReducer } from '../../components/Widgets/SearchHistory/reducers/search-history.reducer';
import { ISwatchState } from '../../components/Widgets/Swatch/interface/ISwatch';
import { swatchReducer } from '../../components/Widgets/Swatch/redux/reducer/swatch.reducer';
import { ICardTokenization } from '../../_foundation/apis/payment/IPayment';
import { IProductAssets } from '../../_foundation/apis/pdp/product-detail.service';
import {
  IAuthenticationState,
  IForgotResetPasswordState,
} from '../../_foundation/interface/Authentication/IAuth';
import { IBreadcrumbState } from '../../_foundation/interface/Breadcrumb/IBreadcrumb';
import {
  ICartState,
  ShowMiniCart,
} from '../../_foundation/interface/Cart/IOrder';
import { ICategoryState } from '../../_foundation/interface/Category/ICategoryCardData';
import { IBillingAddressFormState } from '../../_foundation/interface/Forms/IBillingAddressForm';
import { INetworkErrorState } from '../../_foundation/interface/NetworkErrors/INetworkErrors';
import { INteAlertState } from '../../_foundation/interface/NteAlert/INteAlert';
import { INteButtonState } from '../../_foundation/interface/NteButton/INteButton';
import { IOrderLookUp } from '../../_foundation/interface/Order/IOrderLookUp';
import { IPickupPersonState } from '../../_foundation/interface/PickupPerson/IPickupPerson';
import {
  IProductApiResult,
  IProductList,
  IProductListState,
  ISearchDisplayState,
} from '../../_foundation/interface/ProductList/IProductList';
import { IRviState } from '../../_foundation/interface/Rvi/IRvi';
import { ISaveToListState } from '../../_foundation/interface/SaveToList/ISaveToList';
import { SuggestTypeSuggestionView } from '../../_foundation/interface/SearchSiteContent/ISearchSuggestionContent';
import { ISeoState, IWidgetInfo } from '../../_foundation/interface/Seo/ISeo';
import { IGetUsableShippingState } from '../../_foundation/interface/Shipping/IUsableShipping';
import {
  IDeliveryOptionAndStorePickUp,
  IStoreLocatorFailState,
  IStoreLocatorSearchState,
  IStoreLocatorState,
} from '../../_foundation/interface/StoreLocator/IStoreLocator';
import { IStorePickupState } from '../../_foundation/interface/StorePickup/IStorePickup';
import { ITaxInformationState } from '../../_foundation/interface/TaxInformation/ITaxInformation';
import { IWishListState } from '../../_foundation/interface/WishList/IWishList';
import { authenticationReducer } from './auth.reducer';
import { backdropReducer, IBackdropState } from './backdrop';
import { billingAddressFormReducer } from './billingAddressForm.reducer';
import { breadcrumbReducer } from './breadcrumb.reducer';
import { buttonReducer } from './button.reducer';
import { cartReducer } from './cart.reducer';
import { categoriesReducer } from './categories.reducer';
import { countryListReducer, ICountryListState } from './countryList.reducer';
import { espotDataReducer, IEspotDataState } from './espotData.reducer';
import {
  extendedPricingReducer,
  IExtendedPricingState,
} from './extendedPricing.reducer';
import { forgotResetPasswordReducer } from './forgot-reset-password.reducer';
import { IMediaModal, mediaModalReducer } from './mediaModal.reducer';
import { networkErrorReducer } from './network-errors.reducer';
import { orderReducer } from './order.reducer';
import { orderConfirmationReducer } from './orderConfirmation.reducer';
import { orderDetailsReducer } from './orderDetails.reducer';
import { orderLookUpReducer } from './orderLookUp.reducer';
import { paymentReducer } from './payment.reducer';
import { pickupPersonReducer } from './pickupPerson.reducer';
import { productAssetsReducer } from './productAssets.reducer';
import {
  IProductDetailState,
  productDetailReducer,
} from './productDetail.reducer';
import {
  productListPageReducer,
  productListStorageReducer,
  searchDisplayReducer,
} from './productList.reducer';
import { ISearchState, searchReducer } from './search.reducer';
import { NteSiteState, siteReducer } from './site.reducer';
import {
  deliveryOptionAndStorePickUpReducer,
  storeLocatorFailReducer,
  storeLocatorReducer,
  storeLocatorSearchReducer,
} from './storeLocator';
import { storePickupReducer } from './storePickup.reducer';
import { suggestionReducer } from './suggestion.reducer';
import { taxInformationReducer } from './tax-information.reducer';
import { usableShippingReducer } from './usable-shipping-info.reducer';
import { wishListReducer } from './wish-list.reducer';
import { YottaLoadState, yottaReducer } from './yotta.reducer';

export interface RootReducerState {
  menuListReducer: NteMenuListState;
  site: NteSiteState;
  seo: ISeoState;
  storeLocator: IStoreLocatorState;
  yottaLoadState: YottaLoadState;
  backdrop: IBackdropState;
  categories: ICategoryState;
  storeLocatorSearch: IStoreLocatorSearchState;
  nteAlert: INteAlertState;
  productList: IProductListState;
  tempProductList: IProductApiResult;
  userProductList: ISaveToListState;
  authentication: IAuthenticationState;
  nteButton: INteButtonState;
  networkError: INetworkErrorState;
  searchHistory: ISearchHistoryState;
  wishList: IWishListState;
  breadcrumb: IBreadcrumbState;
  countryList: ICountryListState;
  forgotResetPassword: IForgotResetPasswordState;
  taxInformation: ITaxInformationState;
  suggestion: SuggestTypeSuggestionView;
  cart: ICartState;
  cartDetails: IProductList;
  orderLookUp: IOrderLookUp;
  miniCart: ShowMiniCart;
  rvi: IRviState;
  pdpData: IProductDetailState;
  usableShipping: IGetUsableShippingState;
  deliveryOptionAndStorePickUp: IDeliveryOptionAndStorePickUp;
  searchDisplay: ISearchDisplayState;
  orderConfirmation: ICartState;
  pickupPerson: IPickupPersonState;
  espot: IEspotDataState;
  widgetInfo: IWidgetInfo;
  storePickup: IStorePickupState;
  payment: ICardTokenization;
  mediaModalData: IMediaModal;
  productAssets: IProductAssets;
  checkout: ICheckoutState;
  swatch: ISwatchState;
  storelocatorFail: IStoreLocatorFailState;
  extendedPricing: IExtendedPricingState;
  search: ISearchState;
  billingAddressForm: IBillingAddressFormState;
}

/**
 * Include component specific reducers
 * that needs to be used via redux.
 */
const reducers = {
  menuListReducer,
  site: siteReducer,
  seo: seoReducer,
  storeLocator: storeLocatorReducer,
  yottaLoadState: yottaReducer,
  backdrop: backdropReducer,
  categories: categoriesReducer,
  storeLocatorSearch: storeLocatorSearchReducer,
  nteAlert: nteAlertReducers,
  productList: productListPageReducer,
  tempProductList: productListStorageReducer,
  userProductList: saveToListReducer,
  authentication: authenticationReducer,
  nteButton: buttonReducer,
  networkError: networkErrorReducer,
  searchHistory: searchHistoryReducer,
  wishList: wishListReducer,
  breadcrumb: breadcrumbReducer,
  countryList: countryListReducer,
  forgotResetPassword: forgotResetPasswordReducer,
  taxInformation: taxInformationReducer,
  suggestion: suggestionReducer,
  cart: orderReducer,
  cartDetails: orderDetailsReducer,
  orderLookUp: orderLookUpReducer,
  miniCart: cartReducer,
  rvi: rviReducer,
  pdpData: productDetailReducer,
  usableShipping: usableShippingReducer,
  deliveryOptionAndStorePickUp: deliveryOptionAndStorePickUpReducer,
  searchDisplay: searchDisplayReducer,
  orderConfirmation: orderConfirmationReducer,
  pickupPerson: pickupPersonReducer,
  espot: espotDataReducer,
  widgetInfo: widgetInfoReducer,
  storePickup: storePickupReducer,
  payment: paymentReducer,
  mediaModalData: mediaModalReducer,
  productAssets: productAssetsReducer,
  checkout: checkoutReducer,
  swatch: swatchReducer,
  storelocatorFail: storeLocatorFailReducer,
  extendedPricing: extendedPricingReducer,
  search: searchReducer,
  billingAddressForm: billingAddressFormReducer,
};

const rootReducer = combineReducers(reducers);

// Export.
export default rootReducer;
