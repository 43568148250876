import { AxiosError } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import {
  backButtonTrigger,
  formatSearchTerm,
  getLocalStorage,
  recentlyVisitedLinks,
  setLocalStorage,
} from '../../../utils/utils';
import { categoryService } from '../../../_foundation/apis/search/category/category.service';
import { productsService } from '../../../_foundation/apis/search/products/products.service';
import { searchProductsContentService } from '../../../_foundation/apis/search/search-siteContent/searchProducts.service';
import { CANCEL_ON_UNMOUNT } from '../../../_foundation/constants/cancel';
import { REDIRECT_TO_ERROR_PAGE_ACTION } from '../../actions/network-errors.actions';
import {
  CURRENT_BRAND_IDENTIFIER_ACTION,
  CURRENT_BRAND_ID_ACTION,
  CURRENT_BRAND_INFO_ACTION,
  FACET_LOADING_ACTION,
  FETCH_INVENTORY_BY_IDENTIFIER_SUCCESS_ACTION,
  FETCH_INVENTORY_INFO_SUCCESS_ACTION,
  FETCH_PRIVATE_BRANDS_SUCCESS_ACTION,
  FETCH_SUB_CATEGORIES_SUCCESS_ACTION,
  GET_PRODUCT_LIST_SUCCESS_ACTION,
  GET_PRODUCT_LIST_SUCCESS_ACTION_STORAGE,
  NO_SEARCH_RESULTS_FOUND_ACTION,
  REDIRECT_TO_PDP_ACTION,
  SEARCH_DISPLAY_SUCCESS_ACTION,
  SEARCH_PRODUCT_LIST_ACTION,
  SET_PLP_PAGE_LOADING_ACTION,
  SHOW_PLP_ERROR_ACTION,
  UPDATE_FACETS_SUCCESS_ACTION,
} from '../../actions/productList.actions';
import {
  addData,
  fetchData,
  getStoreData,
  update,
} from '../../reducers/indexedDB';
import { cloneDeep, has, isEqual } from 'lodash';
import { productListStorageSelector } from '../../selectors/productList.selector';
import { site } from '../../../_foundation/constants/site';

export function* getProductList(action: any): Generator {
  try {
    const { payload } = action;
    const backAction = backButtonTrigger();
    const fetchedData: any = yield getStoreData(); // Initial fetched store data
    let cachedProductList: any[] = fetchedData ? [...fetchedData] : [];
    let response: any;

    if (!backAction) {
      // Handle new data fetch
      response = yield call(productsService.fetchProductsByCategory, payload);
      //setLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION', response);
      yield put(
        GET_PRODUCT_LIST_SUCCESS_ACTION_STORAGE({
          response: JSON.stringify(response),
          meathod: 'fetchProductsBySearchTerm',
          payload: JSON.stringify(action.payload),
        })
      );

      if (payload.pageNumber >= 2) {
        // Append to cached list for pages >= 2
        cachedProductList.push(response.catalogEntryView);
        yield update(cachedProductList);
      } else {
        // Initialize cached list for the first page
        cachedProductList = [response.catalogEntryView];
        yield addData(cachedProductList);
      }
    } else {
      // Handle back navigation
      if (cachedProductList.length < payload.pageNumber) {
        response = yield call(productsService.fetchProductsByCategory, payload);
        yield put(
          GET_PRODUCT_LIST_SUCCESS_ACTION_STORAGE({
            response: JSON.stringify(response),
            meathod: 'fetchProductsByCategory',
            payload: JSON.stringify(action.payload),
          })
        );
        cachedProductList.push(response.catalogEntryView);
        yield update(cachedProductList);
      } else {
        const siteResponse: any = yield select(productListStorageSelector);
        response = JSON.parse(siteResponse.response);
      }
    }

    // Dispatch success action
    yield put(
      GET_PRODUCT_LIST_SUCCESS_ACTION({
        ...response,
        userType: payload.userType,
        isBrandPlp: payload.isBrandPlp,
        searchPlp: false,
        pageNumber: payload.pageNumber,
        previousData: cachedProductList,
      })
    );
  } catch (e: any) {
    const axiosError: AxiosError = e;
    const { response } = axiosError;

    if (response?.status === 500) {
      yield put(SHOW_PLP_ERROR_ACTION(true));
      return;
    }

    console.error(e);

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status?.toString() || 'Unknown',
          errorKey: response?.config?.url || 'Unknown',
          errorMessage: response?.data || 'An error occurred',
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* filterProductList(action: any): Generator {
  try {
    const { pageNumber, isLoadMoreBack, userType, isDirectBack } =
      action.payload;

    // Determine loading state
    yield put(
      pageNumber === 0
        ? FACET_LOADING_ACTION(true)
        : SET_PLP_PAGE_LOADING_ACTION(true)
    );

    const backAction = backButtonTrigger();
    const fetchedData: any = yield getStoreData(); // Fetch cached data
    const cachedPreviousResponse: any = yield select(
      productListStorageSelector
    );

    // Compare new facets with cached facets
    const isFacetSame = isEqual(
      JSON.parse(cachedPreviousResponse?.payload || '{}'),
      action.payload
    );

    // Fetch products if necessary
    let shouldFetch = !backAction || isLoadMoreBack || !isFacetSame;
    if (isDirectBack) {
      shouldFetch = false;
    }

    const response: any = shouldFetch
      ? yield call(productsService.fetchProductsByCategory, action.payload)
      : fetchedData;

    if (shouldFetch) {
      // Save fetched response to storage
      yield put(
        GET_PRODUCT_LIST_SUCCESS_ACTION_STORAGE({
          response: JSON.stringify(response),
          meathod: 'fetchProductsByCategory',
          payload: JSON.stringify(action.payload),
        })
      );
    }

    // Update cached product list
    let cachedProductList = fetchedData ? [...fetchedData] : [];
    if (shouldFetch) {
      cachedProductList.push(response.catalogEntryView);

      if (pageNumber >= 2) {
        yield update(cachedProductList);
      } else {
        yield addData([response.catalogEntryView]);
      }

      // Update facets and state
      yield put(
        UPDATE_FACETS_SUCCESS_ACTION({
          response,
          pageNumber,
          userType,
          searchPlp: false,
          cachedProductList,
          // Indicate if using cached data
        })
      );
    } else {
      let cachedProductList: any[] = [];
      if (fetchedData) {
        cachedProductList = [...fetchedData];
      }
      const siteResponse: any = yield select(productListStorageSelector);
      yield put(
        UPDATE_FACETS_SUCCESS_ACTION({
          response: JSON.parse(siteResponse.response),
          pageNumber,
          searchPlp: true,
          cachedProductList,
          isBack: true,
        })
      );
    }
  } catch (error: any) {
    const axiosError = error as AxiosError;
    const { response } = axiosError;

    if (response?.status === 500) {
      yield put(SHOW_PLP_ERROR_ACTION(true));
      return;
    }

    if (error.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status?.toString(),
          errorKey: response?.config?.url,
          errorMessage: response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* getProductListForStore(action: any): Generator {
  try {
    let response: any;
    const backAction = backButtonTrigger();
    const getStoreDataPromise_init = getStoreData();
    const fetchedData: any = yield getStoreDataPromise_init;
    let previousData: any;
    if (!backAction) {
      response = yield call(
        productsService.fetchProductsByStoreIdentifier,
        action.payload
      );

      yield put(
        GET_PRODUCT_LIST_SUCCESS_ACTION_STORAGE({
          response: JSON.stringify(response),
          meathod: 'fetchProductsByStoreIdentifier',
          payload: JSON.stringify(action.payload),
        })
      );
      if (action.payload.pageNumber >= 2) {
        // Handle cachedProductList here if needed
        let cachedProductList: any[] = [];
        if (fetchedData) {
          cachedProductList = [...fetchedData];
        }
        cachedProductList.push(response.catalogEntryView);
        const dataToAdd = cachedProductList;
        yield update(dataToAdd);
      } else {
        const dataToAdd = [response.catalogEntryView];
        yield addData(dataToAdd);
        previousData = [response.catalogEntryView];
      }
    } else {
      let cachedProductList: any[] = [];
      if (fetchedData) {
        cachedProductList = [...fetchedData];
      }
      if (cachedProductList.length < action.payload.pageNumber) {
        const { siteResponse }: any = yield call(
          productsService.fetchProductsByCategory,
          action.payload
        );
        response = siteResponse;
        yield put(
          GET_PRODUCT_LIST_SUCCESS_ACTION_STORAGE({
            response: JSON.stringify(siteResponse),
            meathod: 'fetchProductsByStoreIdentifier',
            payload: JSON.stringify(action.payload),
          })
        );
        cachedProductList.push(response.catalogEntryView);
        yield update(cachedProductList);
      } else {
        const siteResponse: any = yield select(productListStorageSelector);
        response = JSON.parse(siteResponse.response);
      }
    }

    const getStoreDataPromise = getStoreData();
    previousData = yield getStoreDataPromise;

    yield put(
      GET_PRODUCT_LIST_SUCCESS_ACTION({
        ...response,
        userType: action.payload.userType,
        searchPlp: false,
        pageNumber: action.payload.pageNumber,
        previousData: previousData,
      })
    );
  } catch (e: any) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    if (response?.status === 500) {
      yield put(SHOW_PLP_ERROR_ACTION(true));

      return;
    }

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status.toString(),
          errorKey: response?.config.url,
          errorMessage: response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* filterProductListForShopStore(action: any): Generator {
  try {
    const pageNumber = action.payload.pageNumber;

    if (pageNumber === 0) {
      yield put(FACET_LOADING_ACTION(true));
    } else {
      yield put(SET_PLP_PAGE_LOADING_ACTION(true));
    }

    const response = yield call(
      productsService.fetchProductsByStoreIdentifier,
      action.payload
    );

    yield put(
      UPDATE_FACETS_SUCCESS_ACTION({
        response,
        pageNumber: action.payload.pageNumber,
        searchPlp: false,
      })
    );
  } catch (e: any) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    if (response?.status === 500) {
      yield put(SHOW_PLP_ERROR_ACTION(true));

      return;
    }

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status.toString(),
          errorKey: response?.config.url,
          errorMessage: response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* getPrivateBrands(action: any): Generator {
  try {
    const response = yield call(
      productsService.fetchPrivateBrands,
      action.payload
    );

    yield put(FETCH_PRIVATE_BRANDS_SUCCESS_ACTION(response));
  } catch (e) {
    console.error(e);
  }
}

export function* getProductListByKeyword(action: any): Generator {
  try {
    const { payload } = action;
    const { searchKeyword, pageNumber, storeID } = payload;
    let siteResponse,
      isPdpRedirect,
      cachedProductList: any[] = [];
    const fetchedData: any = yield fetchData(); // Fetch cached data once
    const backAction = payload?.isBack || backButtonTrigger() ? true : false;

    // Check for repeated calls
    const repeatedCall = recentlyVisitedLinks(payload);
    if (!backAction) {
      // Fetch products by search term
      const response: any = yield call(
        searchProductsContentService.fetchProductsBySearchTerm,
        payload
      );
      siteResponse = response.siteResponse;
      isPdpRedirect = response.isPdpRedirect;

      // Cache response and payload (Store the siteResponse as JSON)
      yield put(
        GET_PRODUCT_LIST_SUCCESS_ACTION_STORAGE({
          response: JSON.stringify(siteResponse), // Store the response as a JSON string
          meathod: 'fetchProductsBySearchTerm',
          payload: JSON.stringify(action.payload),
        })
      );
      setLocalStorage('LAST_ACTIONCALLED', payload);
      setLocalStorage('isPdpRedirect', isPdpRedirect);

      // Handle paginated product list
      if (pageNumber >= 2) {
        cachedProductList = fetchedData ? [...fetchedData] : [];
        cachedProductList.push(siteResponse.catalogEntryView);
        yield update(cachedProductList);
      } else {
        cachedProductList = [siteResponse.catalogEntryView];
        yield addData(cachedProductList);
      }
    } else {
      // Handle repeated calls and caching
      cachedProductList = fetchedData ? [...fetchedData] : [];
      if (cachedProductList.length < pageNumber) {
        const response: any = yield call(
          searchProductsContentService.fetchProductsBySearchTerm,
          payload
        );
        siteResponse = response.siteResponse;
        isPdpRedirect = response.isPdpRedirect;

        yield put(
          GET_PRODUCT_LIST_SUCCESS_ACTION_STORAGE({
            response: JSON.stringify(siteResponse), // Store response as JSON string
            meathod: 'fetchProductsBySearchTerm',
            payload: JSON.stringify(action.payload),
          })
        );
        cachedProductList.push(siteResponse.catalogEntryView);
        yield update(cachedProductList);
      } else {
        // Retrieve and decode the cached response
        const response: any = yield select(productListStorageSelector);
        siteResponse = JSON.parse(response.response); // Decode the JSON string back to an object
      }
    }

    // Handle PLP success or PDP redirection
    if (!isPdpRedirect) {
      yield put(
        GET_PRODUCT_LIST_SUCCESS_ACTION({
          ...siteResponse,
          searchPlp: true,
          searchTerm: searchKeyword,
          pageNumber,
          previousData: cachedProductList,
        })
      );
    } else if (siteResponse) {
      const parentCatalogEntryID =
        siteResponse.contents[0]?.parentCatalogEntryID;
      const seoHref = parentCatalogEntryID
        ? yield call(productsService.fetchProductById, {
            id: [parentCatalogEntryID],
            storeID,
          })
        : siteResponse.contents[0]?.seo?.href;

      if (seoHref) {
        yield put(REDIRECT_TO_PDP_ACTION(seoHref));
      }
    }
  } catch (e: any) {
    if (e?.message !== CANCEL_ON_UNMOUNT) {
      yield put(NO_SEARCH_RESULTS_FOUND_ACTION());
    }
  }
}

export function* updateProductListByKeyword(action: any): Generator {
  try {
    const { pageNumber, isLoadMoreBack } = action.payload;

    // Determine loading state
    yield put(
      pageNumber === 0
        ? FACET_LOADING_ACTION(true)
        : SET_PLP_PAGE_LOADING_ACTION(true)
    );

    const fetchedData: any = yield fetchData();

    // Check for repeated calls
    const lastCall = getLocalStorage('LAST_ACTIONCALLED_UPDATE') || {};
    const repeatedCall = isRepeatedCall(lastCall, action.payload);
    const backAction = backButtonTrigger();

    if (!backAction || isLoadMoreBack) {
      // Fetch new data
      const { siteResponse }: any = yield call(
        searchProductsContentService.fetchProductsBySearchTerm,
        action.payload
      );

      // Update local storage
      setLocalStorage('LAST_ACTIONCALLED_UPDATE', action.payload);
      //setLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION', siteResponse);
      yield put(
        GET_PRODUCT_LIST_SUCCESS_ACTION_STORAGE({
          response: JSON.stringify(siteResponse),
          meathod: 'fetchProductsByCategory',
          payload: JSON.stringify(action.payload),
          pageNumber: pageNumber,
        })
      );

      // Handle data caching
      let cachedProductList = fetchedData ? [...fetchedData] : [];
      if (pageNumber >= 2) {
        cachedProductList.push(siteResponse.catalogEntryView);
        yield update(cachedProductList);
      } else {
        yield addData([siteResponse.catalogEntryView]);
      }

      // Dispatch success action
      yield put(
        UPDATE_FACETS_SUCCESS_ACTION({
          response: siteResponse,
          pageNumber,
          searchPlp: true,
        })
      );
    } else {
      let cachedProductList: any[] = [];
      if (fetchedData) {
        cachedProductList = [...fetchedData];
      }
      const siteResponse: any = yield select(productListStorageSelector);
      yield put(
        UPDATE_FACETS_SUCCESS_ACTION({
          response: JSON.parse(siteResponse.response),
          pageNumber,
          searchPlp: true,
          cachedProductList,
          isBack: true,
        })
      );
    }
  } catch (e: any) {
    const axiosError: AxiosError = e as AxiosError;

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: axiosError.response?.status.toString(),
          errorKey: axiosError.response?.config.url,
          errorMessage: axiosError.response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

// Utility function for repeated call detection
const isRepeatedCall = (lastCall: any, currentCall: any): boolean => {
  if (!lastCall) return false;

  const { pageNumber: lastPage, ...lastRest } = lastCall;
  const { pageNumber: currentPage, isBack, ...currentRest } = currentCall;

  // Normalize orderBy for comparison
  if ('orderBy' in lastRest && 'orderBy' in currentRest) {
    lastRest.orderBy = Number(lastRest.orderBy);
    currentRest.orderBy = Number(currentRest.orderBy);
  }

  // Compare the rest of the payloads
  const isSamePayload =
    JSON.stringify(lastRest) === JSON.stringify(currentRest);
  const isPageNumberValid = currentPage <= lastPage;

  return isBack || (isSamePayload && isPageNumberValid);
};

export function* getSearchDisplayByKeyword(action: any): Generator {
  try {
    const response: any = yield call(
      searchProductsContentService.fetchSearchDisplayBySearchTerm,
      action.payload
    );

    const { redirecturl, viewTaskName, storeId } = response;

    const searchDisplayResponse = {
      redirecturl: redirecturl,
      viewTaskName: viewTaskName,
      storeId: storeId,
      searchTerm: formatSearchTerm(action.payload.term),
    };

    if (!redirecturl && action.payload?.searchProductParams) {
      yield put(
        SEARCH_PRODUCT_LIST_ACTION(action.payload?.searchProductParams)
      );
    } else {
      yield put(SEARCH_DISPLAY_SUCCESS_ACTION(searchDisplayResponse));
    }

    return response;
  } catch (e) {
    console.error(e);
  }
}

export function* getInventoryInfo(action: any): Generator {
  try {
    const response: any = yield call(
      productsService.fetchInventoryInfo,
      action.payload
    );

    yield put(FETCH_INVENTORY_INFO_SUCCESS_ACTION(response));
  } catch (e) {
    console.error(e);
  }
}

export function* getSubCategories(action: any): Generator {
  try {
    const response: any = yield call(
      categoryService.fetchAllSubcategoriesForCarousel,
      action.payload
    );

    if (Object.keys(response)) {
      yield put(FETCH_SUB_CATEGORIES_SUCCESS_ACTION(response));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* getCategoryByIdentifier(action: any): Generator {
  try {
    const response: any = yield call(
      productsService.fetchCategoryByIdentifier,
      action.payload
    );

    yield put(FETCH_INVENTORY_BY_IDENTIFIER_SUCCESS_ACTION(response));
  } catch (e) {
    console.error(e);
  }
}

export function* getBrandByIdentifier(action: any): Generator {
  try {
    const response: any = yield call(
      productsService.fetchCategoryByIdentifier,
      action.payload
    );

    if (response && response.contents && response.contents[0]) {
      yield put(CURRENT_BRAND_ID_ACTION(response.contents[0].id));

      yield put(
        CURRENT_BRAND_IDENTIFIER_ACTION(response.contents[0].identifier)
      );

      yield put(
        CURRENT_BRAND_INFO_ACTION({
          brandName: response.contents[0].name,
          seo: response.contents[0].seo.href,
        })
      );
    }
  } catch (e) {
    console.error(e);
  }
}
