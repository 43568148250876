import { createReducer, current } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  INetworkError,
  INetworkErrors,
  INetworkErrorState,
  ISessionInvalidError,
  Tier1Error,
} from '../../_foundation/interface/NetworkErrors/INetworkErrors';
import {
  INVALID_URL_ACTION,
  MERGE_CART_ERROR_ACTION,
  NETWORK_ERROR_ACTION,
  REDIRECT_TO_ERROR_PAGE_ACTION,
  RESET_MERGE_CART_ERROR_ACTION,
  RESET_NETWORK_ERROR_ACTION,
  RESET_REDIRECTION_URL_ACTION,
  SESSION_INVALID_ACTION,
  TIER_1_ERROR_ACTION,
} from '../actions/network-errors.actions';

const initNetworkErrorState: INetworkErrorState = {
  errorCode: '',
  errorMessage: '',
  invalidSession: false,
  redirectToErrorPage: false,
  errorKey: '',
  statusCode: 0,
  tier1Error: {
    api: '',
    apiResponseHttpCode: '',
    apiResponsePayload: '',
  },
  redirectionUrl: '',
  hideComponent: false,
  isInvalidUrl: false,
};

const networkErrorReducer = createReducer(initNetworkErrorState, (builder) => {
  builder.addCase(
    SESSION_INVALID_ACTION,
    (state: INetworkErrorState, action: any) => {
      const { response, redirectionUrl } =
        action.payload as ISessionInvalidError;

      const errorData: INetworkErrors = response?.data as INetworkErrors;

      if (errorData && errorData?.errors && errorData.errors.length !== 0) {
        const { errorCode, errorMessage, errorKey } = errorData.errors[0];

        return {
          ...state,
          errorCode,
          errorMessage,
          invalidSession: true,
          errorKey,
          ...{ ...(redirectionUrl && { redirectionUrl }) },
        };
      }
    }
  );

  builder.addCase(RESET_NETWORK_ERROR_ACTION, (state: INetworkErrorState) => {
    return { ...initNetworkErrorState, redirectionUrl: state.redirectionUrl };
  });

  builder.addCase(
    REDIRECT_TO_ERROR_PAGE_ACTION,
    (state: INetworkErrorState, action: any) => {
      const { redirectToErrorPage, errorCode, errorKey, errorMessage } =
        action.payload;

      return {
        ...state,
        redirectToErrorPage,
        errorCode,
        errorKey,
        errorMessage,
      };
    }
  );

  builder.addCase(
    NETWORK_ERROR_ACTION,
    (state: INetworkErrorState, action: any) => {
      const { errorCode, errorKey, hideComponent } =
        action.payload as INetworkError;

      return { ...state, hideComponent, errorCode, errorKey };
    }
  );

  builder.addCase(
    TIER_1_ERROR_ACTION,
    (state: INetworkErrorState, action: any) => {
      const tier1Error = action.payload as Tier1Error;

      return { ...state, tier1Error };
    }
  );

  builder.addCase(
    MERGE_CART_ERROR_ACTION,
    (state: INetworkErrorState, action: any) => {
      const { data } = action.payload.response as AxiosResponse;

      const errorData: INetworkErrors = data as INetworkErrors;

      const { errorCode, errorMessage, errorKey } = errorData.errors[0];

      return {
        ...state,
        errorCode,
        errorMessage,
        errorKey,
      };
    }
  );

  builder.addCase(
    RESET_MERGE_CART_ERROR_ACTION,
    (state: INetworkErrorState) => {
      let currentState = current(state);

      if (
        currentState.errorCode ||
        currentState.errorMessage ||
        currentState.errorKey
      )
        return {
          ...state,
          errorCode: '',
          errorMessage: '',
          errorKey: '',
        };
    }
  );

  builder.addCase(RESET_REDIRECTION_URL_ACTION, (state: INetworkErrorState) => {
    return { ...state, redirectionUrl: '' };
  });

  builder.addCase(
    INVALID_URL_ACTION,
    (state: INetworkErrorState, action: any) => {
      return { ...state, isInvalidUrl: action.payload };
    }
  );
});

export { networkErrorReducer };
