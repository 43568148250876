import { createReducer, current } from '@reduxjs/toolkit';
import { SHOW_BILLING_FORM_ACTION, SHOW_MANUAL_FORM_ACTION } from '../action-types/billingAddressForm.action-types';
import { IBillingAddressFormState } from '../../_foundation/interface/Forms/IBillingAddressForm';


const initialState: IBillingAddressFormState = {
    showBillingForm: false,
    showManualAddressForm: false,
};

const billingAddressFormReducer = createReducer(initialState, (builder) => {
    /**
     * @action SHOW_BILLING_FORM_ACTION
     * controls whether the billing address is shown or not
     */
    builder.addCase(
        SHOW_BILLING_FORM_ACTION,
        (state: IBillingAddressFormState, action: any) => {
            const currentState = current(state);

            const updatedState: IBillingAddressFormState = {
                ...currentState,
                showBillingForm: action.payload
            };

            return {
                ...updatedState,
            };
        }
    );

    /**
     * @action SHOW_MANUAL_FORM_ACTION
     * controls whether the manual address form entry is expanded or not
     */
    builder.addCase(
        SHOW_MANUAL_FORM_ACTION,
        (state: IBillingAddressFormState, action: any) => {
            return { ...state, showManualAddressForm: action.payload };
        }
    );

});

export { billingAddressFormReducer };