export const PAYMENT_METHOD = 'PAYMENT_METHOD';

export const PAYMENT_METHOD_PAYPAL = 'PAYMENT_METHOD_PAYPAL';

export const PAYPAL = 'PAYPAL';

export const PAYPALIOM = 'PayPalIOM';

export const TREVIPAY = 'TREVIPAY';

export const PRE_CHECKOUT_ACTION = 'PRECHECKOUT_CART_ACTION';

export const PRE_CHECKOUT_SUCCESS_ACTION = 'PRECHECKOUT_CART_SUCCESS_ACTION';

export const FILL_OUT_BILLING =
  'Please complete the Billing Address below to continue.';

export const UPDATE_BILLING_PHONE =
  'Please update the phone number assigned to your billing address to continue.';