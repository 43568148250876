import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  COLLECTIONS,
  PROMOTIONS,
  SEE_ALL_DEALS,
  SHOP_STORE,
} from '../../../../constants/routes';
import { SET_CURRENT_CATEGORY_ID_ACTION } from '../../../../redux/actions/categories.actions';
import {
  ADD_CATEGORY_IDS_ACTION,
  FETCH_SUB_CATEGORIES_ACTION,
  GET_PRODUCT_LIST_ACTION,
  GET_PRODUCT_LIST_STORE_ACTION,
  RESET_PRODUCT_LIST_ACTION,
  SET_PRODUCT_LIST_LOADER_ACTION,
  UPDATE_FACETS_ACTION,
  UPDATE_FACETS_ACTION_STORE_IDENFIER_ACTION,
} from '../../../../redux/actions/productList.actions';
import { authenticationSelector } from '../../../../redux/selectors/auth.selector';
import { categoriesSelector } from '../../../../redux/selectors/categories.selector';
import {
  productListSelector,
  productListStorageSelector,
} from '../../../../redux/selectors/productList.selector';
import { useCurrentPathName } from '../../../../utils/hooks/current-pathname/CurrentPathName';
import { useDealsCheck } from '../../../../utils/hooks/deals-check/DealsCheckHooks';
import { useFormattedPath } from '../../../../utils/hooks/formatted-path/formatted-path';
import {
  backButtonTrigger,
  constructPageTitleFromSeo,
  formatQueryParams,
  formatToTitleCase,
  getLocalStorage,
  recentlyVisitedLinks,
} from '../../../../utils/utils';
import { USER_TYPE } from '../../../../_foundation/constants/cookie';
import { UserType } from '../../../../_foundation/enum/User/UserType';
import { useSite } from '../../../../_foundation/hooks/usesite/useSite';
import {
  IFetchProductList,
  IStoreName,
} from '../../../../_foundation/interface/ProductList/IProductList';
import { CategoryIndexPageConstants } from '../../../Pages/CategoryIndexPage/CategoryIndexPageConstants';
import { useUpdateCurrentCategory } from '../../../Seo/hooks/UpdateCurrentCategoryHooks';
import { seoSelector } from '../../../Seo/redux/selector/seo';
import { FacetConstants } from '../../../Widgets/Facets/Facet/FacetConstants';
import { PlplayoutConstants } from '../PlpLayoutConstants';
import { CANCEL_ON_UNMOUNT } from '../../../../_foundation/constants/cancel';
import { fetchData } from '../../../../redux/reducers/indexedDB';
import { isEqual } from 'lodash';

/**
 * @method usePlp Responsible for fetching product list values from server.
 *
 * @method fetchProductList Fetches the initial product list values and values based on facet selection.
 */
const usePlp = () => {
  const { getProductCountPerPage } = PlplayoutConstants;

  const { CATEGORY_QUERY_KEY, BRAND_QUERY_KEY } = FacetConstants;

  const { currentPathName } = useCurrentPathName();

  const { mySite } = useSite();

  const dispatch = useDispatch();

  const { ALL_CATEGORIES_IDENTIFIER } = CategoryIndexPageConstants;

  const productCountPerPage = getProductCountPerPage();

  const userType = getLocalStorage(USER_TYPE);
  const [cancelSource, setCancelSource] = useState<CancelTokenSource>();

  const {
    categoriesData,
    shopAllCategoryId,
    loading: categoriesLoading,
    currentCategoryId: shopAllId,
  } = useSelector(categoriesSelector);

  const {
    productList,
    loading: productListLoading,
    productComparison: { showProductComparisonBar },
    totalProducts,
    selectedFacets,
    maxPrice,
    minPrice,
    pageLoading,
    categoryIdentifiers,
    currentBrandName,
    subCategories,
    subCategory,
  } = useSelector(productListSelector);

  const { userType: currentUserType } = useSelector(authenticationSelector);

  const [lastPageNumber, setLastPageNumber] = useState<number>(1);

  const [currentCategoryId, setCurrentCategoryId] = useState<string>(
    shopAllId ? shopAllId : ''
  );

  const isFacetsAvailable = selectedFacets.length !== 0;

  // Default sort for most popular - 1 (sort by sales rank)
  const [activeSortIndex, setActiveSortIndex] = useState<number>(1);

  const [parentCategoryID, setParentCategoryID] = useState<string>('');

  const [isBrandPlp, setIsBrandPlp] = useState<boolean>(false);

  const [isCollectionPlp, setIsCollectionPlp] = useState<boolean>(false);
  const [isPromotionPlp, setIsPromotionPlp] = useState<boolean>(false);

  const [tokenExternalValue, setTokenExternalValue] = useState<string>('');

  const history = useHistory();

  const seoConfig = useSelector(seoSelector);

  const [categoryQueryParam] = useQueryParam(CATEGORY_QUERY_KEY, StringParam);

  const [brandQueryParam] = useQueryParam(BRAND_QUERY_KEY, StringParam);

  const { formattedPath } = useFormattedPath();

  const { currentDealsFilterValue, isDealsPage } = useDealsCheck();

  const isAdvantageUser = currentUserType === UserType.Advantage;

  const isSeeAllDeals: boolean =
    history.location.pathname.includes(SEE_ALL_DEALS);

  const isShopThisStore: boolean =
    history.location.pathname.split('/').splice(0, 2).join('/') === SHOP_STORE;

  const uniqueId = history.location.pathname.split('/').splice(-1)[0];

  const storeDetail = history.location.state as IStoreName;

  const cachedPreviousResponse: any = useSelector(productListStorageSelector);

  const initCurrentCategoryId = useCallback((): void => {
    if (seoConfig[formattedPath]?.tokenValue) {
      dispatch(
        SET_CURRENT_CATEGORY_ID_ACTION({
          categoryId: seoConfig[formattedPath]?.tokenValue,
        })
      );
    }
  }, [dispatch, formattedPath, seoConfig]);

  useEffect(() => {
    initCurrentCategoryId();
  }, [initCurrentCategoryId]);

  const getCurrentCategoryId = useCallback((): void => {
    if (seoConfig[formattedPath]) {
      setCurrentCategoryId(seoConfig[formattedPath].tokenValue);
    }

    if (isShopThisStore) {
      const seeAllCategory = categoriesData.find(
        (category) => category.identifier === ALL_CATEGORIES_IDENTIFIER
      );
      if (seeAllCategory) {
        setCurrentCategoryId(seeAllCategory?.id);
      }
    }
  }, [
    seoConfig,
    formattedPath,
    isShopThisStore,
    categoriesData,
    ALL_CATEGORIES_IDENTIFIER,
  ]);

  useEffect(() => {
    getCurrentCategoryId();
  }, [getCurrentCategoryId]);

  /**
   * @callback initBrandPlp Initializes the brand plp and it's respective token values.
   */
  const initBrandPlp = useCallback((): void => {
    if (seoConfig[formattedPath]) {
      const isBrandPlp = Boolean(seoConfig[formattedPath]?.isBrandPlp);

      const tokenExternalValue = seoConfig[formattedPath]?.tokenExternalValue;

      setIsBrandPlp(isBrandPlp);

      setTokenExternalValue(tokenExternalValue);
    }
  }, [formattedPath, seoConfig]);

  useEffect(() => {
    initBrandPlp();
  }, [initBrandPlp]);

  /**
   * @callback initCollectionPlp Initializes the collection plp and it's respective token values.
   */
  const initCollectionPlp = useCallback((): void => {
    if (seoConfig[formattedPath]) {
      const isCollectionPlp = window.location.href.includes(COLLECTIONS);
      const tokenExternalValue = seoConfig[formattedPath]?.tokenExternalValue;
      setIsCollectionPlp(isCollectionPlp);
      setTokenExternalValue(tokenExternalValue);
    }
  }, [formattedPath, seoConfig]);

  useEffect(() => {
    initCollectionPlp();
  }, [initCollectionPlp]);

  /**
   * @callback initPromotionPlp Initializes the promotion plp and it's respective token values.
   */
  const initPromotionPlp = useCallback((): void => {
    if (seoConfig[formattedPath]) {
      const isPromotionPlp = window.location.href.includes(PROMOTIONS);
      const tokenExternalValue = seoConfig[formattedPath]?.tokenExternalValue;
      setIsPromotionPlp(isPromotionPlp);
      setTokenExternalValue(tokenExternalValue);
    }
  }, [formattedPath, seoConfig]);

  useEffect(() => {
    initPromotionPlp();
  }, [initPromotionPlp]);

  /**
   * @callback resetBrandPlp resets the brand plp and it's respective token values.
   */
  const resetCustomPlps = useCallback((): void => {
    setIsBrandPlp(false);
    setIsCollectionPlp(false);
    setIsPromotionPlp(false);

    setTokenExternalValue('');
  }, []);

  useEffect(() => {
    resetCustomPlps();

    return () => {
      setIsBrandPlp(false);
      setIsCollectionPlp(false);
      setIsPromotionPlp(false);

      setTokenExternalValue('');
    };
  }, [resetCustomPlps, history.location.pathname]);

  const categoryPageTitle: string = useMemo(() => {
    const pageTitle = constructPageTitleFromSeo(seoConfig, formattedPath);

    if (isBrandPlp && brandQueryParam) {
      return currentBrandName;
    }

    if (isShopThisStore && selectedFacets && selectedFacets.length !== 0) {
      return `${selectedFacets[0].label} at ${storeDetail?.storeName}`;
    }

    if (isShopThisStore && selectedFacets.length === 0) {
      return `Sold At ${storeDetail?.storeName}`;
    }

    if (pageTitle) {
      return pageTitle;
    }

    return formatToTitleCase(currentPathName.replace(/-/g, ' '));
  }, [
    brandQueryParam,
    currentBrandName,
    currentPathName,
    formattedPath,
    isBrandPlp,
    isShopThisStore,
    selectedFacets,
    seoConfig,
    storeDetail,
  ]);

  /**
   * @callback updatePageNumber Updates the page number as 1
   * whenever a facets is selected or unselected.
   */
  const updatePageNumber = useCallback((): void => {
    dispatch(SET_PRODUCT_LIST_LOADER_ACTION({ loading: true }));

    setLastPageNumber(1);
  }, [dispatch]);

  useEffect(() => {
    updatePageNumber();
  }, [selectedFacets, updatePageNumber]);

  useUpdateCurrentCategory();

  const initParentCategoryFacetNavigation = useCallback((): void => {
    if (categoryIdentifiers.length !== 0 && subCategory.identifier && mySite) {
      if (subCategory) {
        dispatch(
          FETCH_SUB_CATEGORIES_ACTION({
            categoryID: subCategory.id,
            storeID: mySite.storeID,
          })
        );

        setParentCategoryID(subCategory.id);
      }
    }
  }, [categoryIdentifiers.length, dispatch, mySite, subCategory]);

  useEffect(() => {
    initParentCategoryFacetNavigation();
  }, [initParentCategoryFacetNavigation]);

  const initParentCategoryID = useCallback((): void => {
    if (
      categoryQueryParam &&
      categoryIdentifiers.length === 0 &&
      mySite &&
      subCategory.id
    ) {
      const ids: any = formatQueryParams('|').remove(categoryQueryParam);

      setParentCategoryID(subCategory.id);

      dispatch(ADD_CATEGORY_IDS_ACTION(ids));

      return;
    }

    if (!categoryQueryParam && mySite) {
      const parentCategoryID =
        isBrandPlp || isDealsPage || isCollectionPlp || isPromotionPlp
          ? shopAllCategoryId
          : currentCategoryId;

      if (subCategories.length === 0 && parentCategoryID) {
        dispatch(
          FETCH_SUB_CATEGORIES_ACTION({
            categoryID: parentCategoryID,
            storeID: mySite.storeID,
          })
        );
      }

      setParentCategoryID(parentCategoryID);
    }
  }, [
    categoryQueryParam,
    categoryIdentifiers.length,
    mySite,
    subCategory.id,
    dispatch,
    isBrandPlp,
    isDealsPage,
    isCollectionPlp,
    isPromotionPlp,
    shopAllCategoryId,
    currentCategoryId,
    subCategories.length,
  ]);

  useEffect(() => {
    initParentCategoryID();
  }, [initParentCategoryID]);

  /**
   * @method fetchProductList Fetches the initial product list values
   * and values based on facet selection.
   *
   * If the selectedFacets in redux state has value then values will be fetched with
   * the facet values applied to the products url.
   *
   * If the selectedFacets in redux state is empty the default values will be fetched.
   */
  const fetchProductList = useCallback(
    async (cancelToken: CancelTokenSource) => {
      try {
        const backAction = backButtonTrigger();

        if (parentCategoryID) {
          if (!categoriesLoading && currentCategoryId) {
            if (window.location.search.length > 1 && backAction) {
              const fetchedData: any = await fetchData();
              const priceFacetIndex = selectedFacets.findIndex(
                (facet) => facet?.isPriceFacet
              );
              const facetValues =
                priceFacetIndex >= 0
                  ? selectedFacets
                      .filter((_, index) => index !== priceFacetIndex)
                      .map(({ value }) => value)
                  : selectedFacets.map(({ value }) => value);

              // Handle dealsFacet logic
              const dealsFacet = isDealsPage
                ? currentDealsFilterValue.toString()
                : isCollectionPlp && mySite?.collectionsPlpFacetKey
                ? `&facet=facets.${mySite.collectionsPlpFacetKey}.value.raw%253A%2522${uniqueId}%2522`
                : isPromotionPlp && mySite?.promotionPlpFacetKey
                ? `&facet=facets.${mySite.promotionPlpFacetKey}.value.raw%253A%2522${uniqueId}%2522`
                : undefined;

              const fetchProductsPayload: IFetchProductList = {
                parentCategoryID,
                storeID: mySite?.storeID,
                catalogID: mySite?.catalogID,
                soldInStoreAttributeId:
                  mySite?.soldinstoreAttributeId ||
                  process.env.REACT_APP_SOLD_IN_STORE_ATTRIBUTE_ID,
                pageNumber: lastPageNumber,
                pageSize: productCountPerPage,
                minPrice,
                maxPrice,
                cancelToken,
                userType,
                isBrandPlp,
                isSeeAllDeals,
                tokenExternalValue,
                storeIdentifier: uniqueId,
                isLoadMoreBack: false,
                isBack: true,
                isDirectBack: backAction,

                promotionAttributeId:
                  mySite?.promotionAttributeId ||
                  process.env.REACT_APP_PROMOTION_ATTRIBUTE_ID,
                ...(facetValues?.length && { facet: facetValues }),
                ...(isSeeAllDeals && isAdvantageUser
                  ? { allDealsAdvantageFilter: mySite?.allDealsAdvantageFilter }
                  : {
                      allDealsNonAdvantageFilter:
                        mySite?.allDealsNonAdvantageFilter,
                    }),
                ...(dealsFacet && { dealsFacet }),
                isCollectionPlp,
                isPromotionPlp,
              };

              if (activeSortIndex > 0) {
                fetchProductsPayload.orderBy = activeSortIndex;
              }
              dispatch(UPDATE_FACETS_ACTION(fetchProductsPayload));
            }
            if (selectedFacets.length !== 0 || (minPrice && maxPrice)) {
              // Extract facet values
              const priceFacetIndex = selectedFacets.findIndex(
                (facet) => facet?.isPriceFacet
              );

              const facetValues =
                priceFacetIndex >= 0
                  ? selectedFacets
                      .filter((_, index) => index !== priceFacetIndex)
                      .map(({ value }) => value)
                  : selectedFacets.map(({ value }) => value);

              // Handle dealsFacet logic
              const dealsFacet = isDealsPage
                ? currentDealsFilterValue.toString()
                : isCollectionPlp && mySite?.collectionsPlpFacetKey
                ? `&facet=facets.${mySite.collectionsPlpFacetKey}.value.raw%253A%2522${uniqueId}%2522`
                : isPromotionPlp && mySite?.promotionPlpFacetKey
                ? `&facet=facets.${mySite.promotionPlpFacetKey}.value.raw%253A%2522${uniqueId}%2522`
                : undefined;

              // Fetch data if needed
              const fetchedData: any = await fetchData();

              const fetchProductsPayload: IFetchProductList = {
                parentCategoryID,
                storeID: mySite?.storeID,
                catalogID: mySite?.catalogID,
                soldInStoreAttributeId:
                  mySite?.soldinstoreAttributeId ||
                  process.env.REACT_APP_SOLD_IN_STORE_ATTRIBUTE_ID,
                pageNumber: lastPageNumber,
                pageSize: productCountPerPage,
                minPrice,
                maxPrice,
                cancelToken,
                userType,
                isBrandPlp,
                isSeeAllDeals,
                tokenExternalValue,
                storeIdentifier: uniqueId,
                isLoadMoreBack: fetchedData.length < lastPageNumber,
                promotionAttributeId:
                  mySite?.promotionAttributeId ||
                  process.env.REACT_APP_PROMOTION_ATTRIBUTE_ID,
                ...(facetValues?.length && { facet: facetValues }),
                ...(isSeeAllDeals && isAdvantageUser
                  ? { allDealsAdvantageFilter: mySite?.allDealsAdvantageFilter }
                  : {
                      allDealsNonAdvantageFilter:
                        mySite?.allDealsNonAdvantageFilter,
                    }),
                ...(dealsFacet && { dealsFacet }),
                isCollectionPlp,
                isPromotionPlp,
              };

              if (activeSortIndex > 0) {
                fetchProductsPayload.orderBy = activeSortIndex;
              }

              // Compare new facets with cached facets
              // fetchProductsPayload.isLoadMoreBack = isFacetSame ? false : true;
              // Dispatch appropriate actions
              if (isShopThisStore) {
                dispatch(
                  UPDATE_FACETS_ACTION_STORE_IDENFIER_ACTION(
                    fetchProductsPayload
                  )
                );
              } else {
                dispatch(UPDATE_FACETS_ACTION(fetchProductsPayload));
              }
            } else if (!minPrice && !maxPrice) {
              // Initial load or with query params
              const dealsFacet = isDealsPage
                ? currentDealsFilterValue.toString()
                : isCollectionPlp && mySite?.collectionsPlpFacetKey
                ? `&facet=facets.${mySite.collectionsPlpFacetKey}.value.raw%253A%2522${uniqueId}%2522`
                : isPromotionPlp && mySite?.promotionPlpFacetKey
                ? `&facet=facets.${mySite.promotionPlpFacetKey}.value.raw%253A%2522${uniqueId}%2522`
                : undefined;

              const fetchProductsPayload: IFetchProductList = {
                parentCategoryID,
                storeID: mySite?.storeID,
                catalogID: mySite?.catalogID,
                pageNumber: lastPageNumber,
                soldInStoreAttributeId:
                  mySite?.soldinstoreAttributeId ||
                  process.env.REACT_APP_SOLD_IN_STORE_ATTRIBUTE_ID,
                pageSize: productCountPerPage,
                cancelToken,
                userType,
                isBrandPlp,
                isSeeAllDeals,
                tokenExternalValue,
                storeIdentifier: uniqueId,
                promotionAttributeId:
                  mySite?.promotionAttributeId ||
                  process.env.REACT_APP_PROMOTION_ATTRIBUTE_ID,
                ...(isSeeAllDeals && isAdvantageUser
                  ? { allDealsAdvantageFilter: mySite?.allDealsAdvantageFilter }
                  : {
                      allDealsNonAdvantageFilter:
                        mySite?.allDealsNonAdvantageFilter,
                    }),
                ...(dealsFacet && { dealsFacet }),
                isCollectionPlp,
                isPromotionPlp,
              };

              if (activeSortIndex > 0) {
                fetchProductsPayload.orderBy = activeSortIndex;
              }

              // Dispatch appropriate actions
              if (isShopThisStore) {
                dispatch(GET_PRODUCT_LIST_STORE_ACTION(fetchProductsPayload));
              } else {
                dispatch(GET_PRODUCT_LIST_ACTION(fetchProductsPayload));
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching product list:', error);
      }
    },
    [
      parentCategoryID,
      categoriesLoading,
      currentCategoryId,
      selectedFacets,
      minPrice,
      maxPrice,
      isDealsPage,
      isCollectionPlp,
      isPromotionPlp,
      mySite?.storeID,
      mySite?.catalogID,
      mySite?.soldinstoreAttributeId,
      mySite?.promotionAttributeId,
      mySite?.allDealsAdvantageFilter,
      mySite?.allDealsNonAdvantageFilter,
      mySite?.collectionsPlpFacetKey,
      mySite?.promotionPlpFacetKey,
      lastPageNumber,
      productCountPerPage,
      userType,
      isBrandPlp,
      isSeeAllDeals,
      tokenExternalValue,
      uniqueId,
      isAdvantageUser,
      activeSortIndex,
      isShopThisStore,
      currentDealsFilterValue,
      dispatch,
    ]
  );

  useEffect(() => {
    setParentCategoryID('');

    dispatch(RESET_PRODUCT_LIST_ACTION({ clearAll: true }));
  }, [dispatch, history.location.pathname]);

  useEffect(() => {
    return () => {
      dispatch(RESET_PRODUCT_LIST_ACTION({ clearAll: true }));
    };
  }, [dispatch]);

  useEffect(() => {
    const cancelToken = axios.CancelToken;

    const cancelSource = cancelToken.source();

    if (mySite) {
      fetchProductList(cancelSource);
    }
    return () => {
      if (cancelSource) {
        cancelSource.cancel(CANCEL_ON_UNMOUNT);
      }
    };
  }, [fetchProductList, mySite]);

  return {
    productList,
    productListLoading,
    showProductComparisonBar,
    totalProducts,
    setLastPageNumber,
    setActiveSortIndex,
    categoryPageTitle,
    activeSortIndex,
    currentCategoryId,
    lastPageNumber,
    pageLoading,
    isFacetsAvailable,
    isBrandPlp,
    isCollectionPlp,
    categoryIdentifiers,
  };
};

export { usePlp };
